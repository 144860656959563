import {
  Typography,
  Flex,
  Input,
  Table,
  Card,
  Form as AntForm,
  TableProps,
  Row,
  Tag,
} from "antd";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../Router";
import { Button } from "../../../../components/Button";
import { useMemo, useReducer } from "react";
import { IService } from "../../../../../domain/entities/Service";
import { useConfig } from "../../../../hooks/useConfig";

const { Text } = Typography;

export const List: React.FC = () => {
  const { services, loading } = useConfig();

  const filterReducer = (filter: any, info: any) => ({
    ...filter,
    ...info,
  });

  const [filter, changeFilter] = useReducer(filterReducer, {
    searchIdentifier: "",
    veterinarian: "",
    status: "",
  });

  const columns: TableProps<IService>["columns"] = useMemo(
    () => [
      {
        title: "Service",
        dataIndex: "service_name",
        key: "service_name",
        render: (_: any, record) => <Text>{record.service_name}</Text>,
      },
      {
        title: "Tags",
        dataIndex: "tags",
        key: "tags",
        responsive: ["lg"],

        render: (_: any, record) => (
          <Flex>
            {record.tags.map((tag) => (
              <Tag>{tag}</Tag>
            ))}
          </Flex>
        ),
      },
    ],
    []
  );

  const filteredServices = useMemo(() => {
    return services.filter((service) => {
      return service.service_name
        .toLowerCase()
        .includes(filter.searchIdentifier.toLowerCase());
    });
  }, [filter.searchIdentifier, services]);

  return (
    <>
      <Card className="w-full">
        <Flex vertical gap="middle">
          <Row>
            <Flex
              gap={10}
              wrap="wrap"
              justify="space-between"
              align="centers"
              className="w-full"
            >
              <Flex gap="large">
                <AntForm>
                  <Flex
                    vertical
                    gap={2}
                    justify="flex-start"
                    align="flex-start"
                  >
                    <Text>Pesquisar</Text>
                    <Input
                      placeholder="Pesquisar..."
                      value={filter.searchIdentifier}
                      onChange={(e) =>
                        changeFilter({ searchIdentifier: e.target.value })
                      }
                    />
                  </Flex>
                </AntForm>
              </Flex>
              <Flex gap="small" wrap="wrap" justify="flex-end" align="end">
                <Link to={ROUTES.DASHBOARD.SERVICE.ADD}>
                  <Button status="info">Novo Serviço</Button>
                </Link>
              </Flex>
            </Flex>
          </Row>
          <Table
            className="w-full border rounded"
            columns={columns}
            dataSource={filteredServices}
            pagination={{ pageSize: 8 }}
            loading={loading}
            rowKey={(row) => row.id}
          />
        </Flex>
      </Card>
    </>
  );
};
