import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import {
  Flex,
  Input,
  Space,
  Table,
  Typography,
  Card,
  DatePicker,
  Select,
  Row,
  Tooltip,
  Modal,
  Form as AntForm,
} from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Router";
import { Button } from "../../../components/Button";
import { FaClock, FaEye } from "react-icons/fa";
import { HiQueueList } from "react-icons/hi2";
import { UserContext } from "../../../../domain/context/user/UserContext";
import {
  CampaignStatus,
  ICampaign,
} from "../../../../domain/entities/Campaign";
import { useCampaign } from "../../../hooks/useCampaign";
import dayjs from "dayjs";
import { TableProps } from "antd/lib";
import { MdCancel } from "react-icons/md";
const { Text } = Typography;

export const List: React.FC = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [form] = AntForm.useForm();

  const { RangePicker } = DatePicker;

  const {
    campaignList,
    loading,
    actions: { handleGetAllCampaign, handlePatchRejectReason },
  } = useCampaign();

  const filterReducer = (filter: any, info: any) => ({
    ...filter,
    ...info,
  });

  const [filter, changeFilter] = useReducer(filterReducer, {
    searchIdentifier: "",
    status: "",
    dates: { start: "", end: "" },
  });

  const openModal = useCallback((id: string) => {
    setSelectedId(id);
    setIsModalOpen(true);
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const filterCampaign = useMemo(() => {
    if (!campaignList) {
      return [];
    }
    return campaignList
      ?.filter((campaignFilter) =>
        campaignFilter.name
          .toLowerCase()
          .includes(filter.searchIdentifier.toLowerCase())
      )
      .filter((campaignFilter) =>
        filter.status !== "" ? campaignFilter.status === filter.status : true
      )
      .filter((campaignFilter) =>
        filter.dates.start !== "" && filter.dates.end !== ""
          ? dayjs(campaignFilter.campaign_start_date).isBetween(
              filter.dates.start,
              filter.dates.end
            ) ||
            dayjs(campaignFilter.campaign_end_date).isSame(
              filter.dates.start
            ) ||
            dayjs(campaignFilter.campaign_end_date).isSame(filter.dates.end)
          : true
      );
  }, [campaignList, filter]);

  const handleSubmit = async (data: any) => {
    const { canceled_reason } = data;
    await handlePatchRejectReason(selectedId, canceled_reason);
    closeModal();
    await handleGetAllCampaign();
  };

  const columns: TableProps<ICampaign>["columns"] = useMemo(
    () => [
      {
        title: "Nome da Campanha",
        dataIndex: "name",
        key: "name",
        ellipsis: {
          showTitle: false,
        },
        render: (_: any, record) => <Text>{record.name}</Text>,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        responsive: ["md"],
        ellipsis: {
          showTitle: false,
        },
        render: (_: any, record) => (
          <Text>
            {CampaignStatus[record.status as keyof typeof CampaignStatus]}
          </Text>
        ),
      },

      {
        title: "Data de Inicio",
        dataIndex: "campaign_start_date",
        key: "campaign_start_date",
        responsive: ["lg"],
        ellipsis: {
          showTitle: false,
        },
        render: (_: any, record) => (
          <Text>{dayjs(record.campaign_start_date).format("DD/MM/YYYY")}</Text>
        ),
      },
      {
        title: "Data do Fim",
        dataIndex: "campaign_end_date",
        key: "campaign_end_date",
        responsive: ["lg"],
        ellipsis: {
          showTitle: false,
        },
        render: (_: any, record) => (
          <Text>{dayjs(record.campaign_end_date).format("DD/MM/YYYY")}</Text>
        ),
      },
      {
        title: "Ações",
        dataIndex: "actions",
        key: "actions",
        width: 150,
        render: (_: any, record) => {
          const canShowButton =
            userContext.user?.scopes?.includes("campaign:pet:update_time") &&
            [
              "SUBSCRIPTION_STARTED",
              "SUBSCRIPTION_ENDED",
              "CAMPAIGN_STARTED",
            ].includes(record.status);

          const canShowScreeningButton = record.campaign_locations.some(
            (location) => {
              return dayjs().isBefore(dayjs(location.date));
            }
          );

          return (
            <Space size="small">
              {userContext.user?.scopes?.includes("campaign:read") && (
                <Button
                  data-testid="info-button-campaign"
                  className="p-2"
                  type="text"
                  onClick={() =>
                    navigate(
                      ROUTES.DASHBOARD.CAMPAIGN.INFO.replace(":id", record.id)
                    )
                  }
                >
                  <FaEye size={18} />
                </Button>
              )}
              {canShowButton && canShowScreeningButton && (
                <Tooltip title="Triagem">
                  <Button
                    data-testid="screening-button-campaign"
                    className="p-2"
                    type="text"
                    onClick={() =>
                      navigate(
                        ROUTES.DASHBOARD.CAMPAIGN.QUEUE.replace(
                          ":id",
                          record.id
                        )
                      )
                    }
                  >
                    <HiQueueList size={18} />
                  </Button>
                </Tooltip>
              )}
              {userContext.user?.scopes?.includes("campaign:pet:read") &&
                ["SUBSCRIPTION_ENDED", "CAMPAIGN_STARTED"].includes(
                  record.status
                ) && (
                  <Tooltip title="Fila">
                    <Button
                      data-testid="queue-button-campaign"
                      className="p-2"
                      type="text"
                      onClick={() =>
                        navigate(
                          ROUTES.DASHBOARD.CAMPAIGN.SCHEDULE.replace(
                            ":id",
                            record.id
                          )
                        )
                      }
                    >
                      <FaClock size={16} />
                    </Button>
                  </Tooltip>
                )}
              {userContext.user?.scopes?.includes("campaign:pet:read") &&
                [
                  "SUBSCRIPTION_ENDED",
                  "SUBSCRIPTION_STARTED",
                  "SCHEDULED",
                ].includes(record.status) && (
                  <Tooltip title="Cancelar Campanha">
                    <Button
                      data-testid="cancel-campaign"
                      className="p-2"
                      type="text"
                      onClick={() => openModal(record.id as string)}
                    >
                      <MdCancel size={18} />
                    </Button>
                  </Tooltip>
                )}
            </Space>
          );
        },
      },
    ],
    [navigate, userContext, openModal]
  );

  const handleClickAdd = () => {
    navigate(ROUTES.DASHBOARD.CAMPAIGN.ADD);
  };

  useEffect(() => {
    handleGetAllCampaign();
  }, [handleGetAllCampaign]);

  return (
    <>
      <Card className="w-full">
        <Flex vertical gap="middle">
          <Row>
            <Flex
              gap={10}
              wrap="wrap"
              justify="space-between"
              align="centers"
              className="w-full"
            >
              <Flex gap="large" wrap>
                <Flex vertical gap={2} justify="flex-start" align="flex-start">
                  <Text>Pesquisar</Text>
                  <Input
                    placeholder="Digite a Campanha"
                    value={filter.searchIdentifier}
                    onChange={(e) =>
                      changeFilter({ searchIdentifier: e.target.value })
                    }
                  />
                </Flex>
                <Flex vertical justify="flex-start" align="flex-start">
                  <Text>Status</Text>
                  <Space wrap>
                    <Select
                      defaultValue={filter.status}
                      value="Selecione..."
                      className="w-60"
                      onChange={(value) => changeFilter({ status: value })}
                      options={[
                        { value: "", label: "Todos" },
                        {
                          value: "CAMPAIGN_STARTED",
                          label: "Campanha iniciada",
                        },
                        {
                          value: "SUBSCRIPTION_STARTED",
                          label: "Inscricão iniciada",
                        },
                        {
                          value: "SUBSCRIPTION_ENDED",
                          label: "Inscricão encerrada",
                        },
                        { value: "CONCLUDED", label: "Concluido" },
                        { value: "CANCELED", label: "Cancelado" },
                        { value: "SCHEDULED", label: "Agendado" },
                      ]}
                    />
                  </Space>
                </Flex>
                <Flex
                  vertical
                  justify="flex-start"
                  align="flex-start"
                  wrap="wrap"
                >
                  <Text>Data</Text>
                  <Space>
                    <RangePicker
                      title="Data"
                      format={"DD/MM/YYYY"}
                      placeholder={["Data Inicial", "Data Final"]}
                      onChange={(value) => {
                        changeFilter({
                          dates: value
                            ? { start: value[0], end: value[1] }
                            : { start: "", end: "" },
                        });
                      }}
                    />
                  </Space>
                </Flex>
              </Flex>
              <Flex gap="small" wrap="wrap" justify="flex-end" align="end">
                {userContext.user?.scopes?.includes("campaign:create") && (
                  <>
                    <Button
                      data-testid="create-campaign"
                      onClick={handleClickAdd}
                      status="info"
                    >
                      Criar Novo
                    </Button>
                  </>
                )}
              </Flex>
            </Flex>
          </Row>
          <Table
            className="w-full border rounded"
            columns={columns}
            dataSource={filterCampaign}
            pagination={{ pageSize: 8 }}
            loading={loading}
            rowKey={(row) => row.id}
          />
        </Flex>
      </Card>

      <Modal
        title="Rejeição de Campanha"
        open={isModalOpen}
        onCancel={closeModal}
        footer={[
          <Button key="back" onClick={closeModal}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            onClick={form.submit}
            data-testid="send-button-modal"
            loading={false}
          >
            Enviar
          </Button>,
        ]}
      >
        <AntForm layout="vertical" onFinish={handleSubmit} form={form}>
          <AntForm.Item
            name="canceled_reason"
            label="Justificativa"
            rules={[
              {
                required: true,
                message: "Por favor, adicione uma justificativa.",
              },
            ]}
          >
            <Input.TextArea
              className="h-32 resize-none"
              placeholder="Adicione um motivo para a rejeição"
            />
          </AntForm.Item>
        </AntForm>
      </Modal>
    </>
  );
};
