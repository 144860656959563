import React, { useContext, useEffect, useMemo, useReducer } from "react";
import { Typography, Flex, Input, Card, Table, Space, Select, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Router";

import { MdModeEdit } from "react-icons/md";
import { FaEye } from "react-icons/fa";

import { IItem, ItemType } from "../../../../domain/entities/Item";
import { useItem } from "../../../hooks/useItem";
import { Button } from "../../../components/Button";
import { UserContext } from "../../../../domain/context/user/UserContext";

const { Text } = Typography;

export const Licitation = () => {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const {
    itemList,
    loading,
    actions: { handleGetAllItem },
  } = useItem();

  const filterReducer = (filter: any, info: any) => ({
    ...filter,
    ...info,
  });

  const [filter, changeFilter] = useReducer(filterReducer, {
    searchIdentifier: "",
    item: "",
  });

  const columns = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "order_id",
        key: "order_id",
        ellipsis: {
          showTitle: false,
        },
      },
      {
        title: "Tipo",
        dataIndex: "type",
        key: "type",
        render: (_: any, record: IItem) => (
          <Text>
            {record.type.toLowerCase() === "licitation"
              ? "Licitação"
              : "Compra Direta"}
          </Text>
        ),
      },
      {
        title: "Já foi aditivado",
        dataIndex: "licitation.is_aditive",
        key: "licitation.is_aditive",
        render: (_: any, record: IItem) => (
          <Text>
            {record.licitation
              ? record.licitation?.is_aditive
                ? "Sim"
                : "Não"
              : "Não consta"}
          </Text>
        ),
      },
      {
        title: "Ações",
        dataIndex: "actions",
        key: "actions",
        width: 120,
        render: (_: any, record: IItem) => {
          return (
            <Flex gap={"small"}>
              <Button
                className="p-2"
                type="text"
                data-testid="view-item"
                onClick={() =>
                  navigate(
                    ROUTES.DASHBOARD.ITEMS.INFO.replace(":id", record.id)
                  )
                }
              >
                <FaEye size={18} />
              </Button>
              {userContext.user?.scopes?.includes(
                "item:licitation:aditivate"
              ) &&
                record.type === ItemType.LICITATION &&
                !record.licitation?.is_aditive && (
                  <Button
                    className="p-2"
                    type="text"
                    data-testid="edit-item"
                    onClick={() =>
                      navigate(
                        ROUTES.DASHBOARD.ITEMS.EDIT.replace(":id", record.id)
                      )
                    }
                  >
                    <MdModeEdit size={18} />
                  </Button>
                )}
            </Flex>
          );
        },
      },
    ],
    [navigate, userContext.user?.scopes]
  );

  const handleAdd = () => {
    navigate(`${ROUTES.DASHBOARD.ITEMS.ADD}?type=licitation`);
  };

  const handleAddDirectPurchase = () => {
    navigate(`${ROUTES.DASHBOARD.ITEMS.ADD}?type=direct_buy`);
  };

  const filterItemList = useMemo(() => {
    if (!itemList) {
      return [];
    }
    return itemList
      ?.filter((itemFilter) =>
        itemFilter.order_id
          .toLowerCase()
          .includes(filter.searchIdentifier.toLowerCase())
      )
      .filter((itemFilter) =>
        filter.item !== ""
          ? itemFilter.type.toLowerCase().startsWith(filter.item.toLowerCase())
          : true
      );
  }, [itemList, filter]);

  useEffect(() => {
    handleGetAllItem();
  }, [handleGetAllItem]);

  return (
    <Card className="w-full">
      <Flex vertical gap="middle">
        <Row>
          <Flex
            gap={10}
            wrap="wrap"
            justify="space-between"
            align="centers"
            className="w-full"
          >
            <Flex gap="large">
              <Flex vertical gap={2} justify="flex-start" align="flex-start">
                <Text>Pesquisar</Text>
                <Input
                  placeholder="Pesquisar..."
                  value={filter.searchIdentifier}
                  onChange={(e) =>
                    changeFilter({ searchIdentifier: e.target.value })
                  }
                  className="ml-2 w-40"
                />
              </Flex>
              <Flex vertical gap={2} justify="flex-start" align="flex-start">
                <Text>Tipo</Text>
                <Space wrap>
                  <Select
                    defaultValue={filter.item}
                    className="w-40"
                    onChange={(value) => changeFilter({ item: value })}
                    options={[
                      { value: "", label: "Todos" },
                      { value: "LICITATION", label: "Licitação" },
                      { value: "DIRECT_BUY", label: "Compra Direta" },
                    ]}
                  />
                </Space>
              </Flex>
            </Flex>
            <Flex gap="small" wrap="wrap" justify="flex-end" align="end">
              {userContext.user?.scopes?.includes("item:create") && (
                <>
                  <Button status="info" onClick={handleAddDirectPurchase}>
                    Cadastrar Compra Direta
                  </Button>
                  <Button status="info" onClick={handleAdd}>
                    Cadastrar Licitação
                  </Button>
                </>
              )}
            </Flex>
          </Flex>
        </Row>
        <Table
          className="w-full border rounded"
          columns={columns}
          dataSource={filterItemList}
          pagination={{ pageSize: 8 }}
          loading={loading}
          rowKey={(row) => row.id}
        />
      </Flex>
    </Card>
  );
};
