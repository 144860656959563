import { Card, Flex, Typography } from "antd";
import React, { useCallback } from "react";
import { IPet, PetGender, TypeOfHome } from "../../../domain/entities/Pet";

const { Text } = Typography;

type PetCardProps = IPet;

export const PetCard: React.FC<PetCardProps> = ({
  age,
  gender,
  name,
  is_castrated,
  race,
  type_of_home,
  weight,
  species,
  microchip_number,
}) => {
  const renderText = useCallback((field: string | undefined | null) => {
    return <Text>{field || "Não reconhecido"}</Text>;
  }, []);

  return (
    <Card title="Sobre o Animal">
      <Flex gap={"large"}>
        <Flex gap={"small"} vertical>
          <Flex gap="small">
            <Text strong>Nome:</Text>
            {renderText(name)}
          </Flex>
          <Flex gap="small">
            <Text strong>Gênero:</Text>
            {renderText(PetGender[gender as keyof typeof PetGender])}
          </Flex>
          <Flex gap="small">
            <Text strong>Idade: </Text>
            {renderText(age)}
          </Flex>
          <Flex gap="small">
            <Text strong>Peso:</Text>
            {renderText(weight)}
          </Flex>
          <Flex gap="small">
            <Text strong>Raça:</Text>
            {renderText(race || "SRD")}
          </Flex>
          <Flex gap="small">
            <Text strong>Espécie:</Text>
            {renderText(species)}
          </Flex>
          <Flex gap="small">
            <Text strong>É castrado:</Text>
            <Text>{is_castrated ? "Sim" : "Não"}</Text>
          </Flex>
          <Flex gap="small">
            <Text strong>Tipo de Moradia:</Text>
            <Text>
              {type_of_home
                ? TypeOfHome[type_of_home as keyof typeof TypeOfHome]
                : "Não reconhecido"}
            </Text>
          </Flex>
          <Flex gap="small">
            <Text strong>Número de Microchip:</Text>
            {renderText(microchip_number)}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
