import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Carousel,
  Typography,
  Flex,
  Form as AntForm,
  Timeline,
  DatePickerProps,
  Empty,
} from "antd";
import { Button } from "../../../components/Button";
import { useDenouncement } from "../../../hooks/useDenouncement";
import { useNavigate, useParams } from "react-router-dom";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { ROUTES } from "../../../Router";
import dayjs from "dayjs";
import { DenouncementStatus } from "../../../../domain/entities/Denouncement";
import { UserContext } from "../../../../domain/context/user/UserContext";
import { NewFactModal } from "../../../components/NewFactModal";

const { Text } = Typography;

const TIMELINE_COLOR: any = {
  OPEN: "green",
  CLOSED: "red",
  ON_GOING: "yellow",
  EXTERNAL_PROCESS: "blue",
};
export const Info: React.FC = () => {
  const userContext = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blurImage, setBlurImage] = useState(true);
  const [currentStatus, setCurrentStatus] = useState("");
  const [formattedDate, setFormattedDate] = useState("");
  const [form] = AntForm.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    loading,
    denouncement,
    actions: { handleGetDenouncement, handlePostDenouncementInfo },
  } = useDenouncement();

  const toggleBlurImage = () => {
    setBlurImage(!blurImage);
  };

  const updateStatus = async (value: string) => {
    setCurrentStatus(value);
  };

  const redirectReport = () => {
    if (denouncement?.incident_report_link) {
      window.open(denouncement.incident_report_link, "_blank");
    }
  };

  const redirectToOpenTicket = () => {
    navigate(`${ROUTES.DASHBOARD.TICKET.ADD}?denouncement_id=${id}`);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDatePickerChange: DatePickerProps["onChange"] = (date) => {
    if (date) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setFormattedDate(formattedDate);
    }
  };

  const handleSubmit = async (values: any) => {
    const valuesJustification = {
      new_fact: values.justification,
      file_url: values.denouncement_file,
      date: formattedDate,
      status: currentStatus,
    };

    await handlePostDenouncementInfo(id as string, valuesJustification);
    form.resetFields();
    await handleGetDenouncement(id as string);

    closeModal();
  };

  useEffect(() => {
    if (id) {
      handleGetDenouncement(id);
    }
  }, [id, handleGetDenouncement]);

  useEffect(() => {
    if (denouncement) {
      setCurrentStatus(denouncement.status);
    }
  }, [denouncement]);

  return (
    <>
      <Flex vertical gap="small" align="flex-end" className="w-full">
        <Card
          className="w-full"
          title={
            <Flex wrap="wrap" gap="small">
              <Text className="text-xl font-bold">Informações da Denuncia</Text>
              <Row className="gap-3 ml-auto">
                <Button
                  type="default"
                  status="info"
                  onClick={redirectToOpenTicket}
                >
                  Abrir Chamado
                </Button>

                {denouncement?.has_incident_report && (
                  <Button
                    type="default"
                    status="info"
                    data-testid="incident-report-button"
                    onClick={redirectReport}
                  >
                    Boletim de Ocorrencia
                  </Button>
                )}

                {userContext.user?.scopes?.includes(
                  "denouncement:create:new_fact"
                ) && (
                  <Button
                    type="default"
                    status="info"
                    data-testid="open-modal-button"
                    onClick={openModal}
                  >
                    Fato Novo
                  </Button>
                )}
              </Row>
            </Flex>
          }
        >
          <Row gutter={18}>
            <Col xs={24} sm={24} md={12} lg={8}>
              <Carousel dots={true} dotPosition="bottom">
                <div className="relative ">
                  <Button
                    onClick={toggleBlurImage}
                    className="absolute top-2 right-2 z-50"
                    icon={
                      blurImage ? <EyeInvisibleOutlined /> : <EyeOutlined />
                    }
                  />
                  <img
                    src={denouncement?.image_url}
                    alt=""
                    className={`w-full object-contain max-h-96   ${
                      blurImage ? "blur-xl" : ""
                    }`}
                  />
                </div>
              </Carousel>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <Flex gap="small" align="start" vertical>
                <Card
                  loading={loading}
                  title={
                    <div className="text-lg">Informações do Denunciante</div>
                  }
                  className="w-full h-300"
                >
                  <Flex gap="small" align="start" vertical>
                    <Text className="text-lg font-semibold">
                      Nome Completo:{" "}
                      {denouncement?.is_anonymous === false ? (
                        <Text className="text-base font-normal">
                          {denouncement?.tutor.user.full_name}
                        </Text>
                      ) : (
                        <Text className="text-base font-normal">Anônimo</Text>
                      )}
                    </Text>

                    <Text className="text-lg font-semibold">
                      CPF:{" "}
                      <Text className="text-base font-normal">
                        {String(denouncement?.tutor.user.id_number).replace(
                          /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
                          "$1.$2.$3-$4"
                        )}
                      </Text>
                    </Text>
                    <Text className="text-lg font-semibold">
                      Telefone:{" "}
                      <Text className="text-base font-normal">
                        {denouncement?.tutor.user.phone.replace(
                          /^(\d{2})(\d{5})(\d{4})$/,
                          "($1) $2-$3"
                        )}
                      </Text>
                    </Text>
                    <Text className="text-lg font-semibold">
                      Anônimo:{" "}
                      {denouncement?.is_anonymous === false ? (
                        <Text className="text-base font-normal">Não</Text>
                      ) : (
                        <Text className="text-base font-normal">Sim</Text>
                      )}
                    </Text>
                  </Flex>
                </Card>
              </Flex>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <Card
                loading={loading}
                title={<div className="text-lg"> Denuncia</div>}
                className="w-full mb-4"
              >
                <Flex gap="small" align="start" vertical>
                  <Text className="text-lg font-semibold">
                    Localização:{" "}
                    <Text className="text-base font-normal">
                      {denouncement?.location_address}
                    </Text>
                  </Text>
                  <Text className="text-lg font-semibold">
                    Referência:{" "}
                    <Text className="text-base font-normal">
                      {denouncement?.location_reference}
                    </Text>
                  </Text>

                  <Text className="text-lg font-semibold">
                    Tipo de denúncia:{" "}
                    <Text className="text-base font-normal">
                      {denouncement?.type}
                    </Text>
                  </Text>

                  <Text className="text-lg font-semibold">
                    Data ocorrida:{" "}
                    <Text className="text-base font-normal">
                      {dayjs(denouncement?.date_ocurred).format("DD/MM/YYYY")}
                    </Text>
                  </Text>

                  <Text className="text-lg font-semibold">
                    Espécie:{" "}
                    <Text className="text-base font-normal">
                      {denouncement?.species}
                    </Text>
                  </Text>

                  <Text className="text-lg font-semibold">
                    Observações:{" "}
                    <Text className="text-base font-normal">
                      {denouncement?.observation}
                    </Text>
                  </Text>
                </Flex>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Card title="Linha do Tempo" loading={loading}>
                {denouncement?.denouncement_item &&
                denouncement.denouncement_item.length > 0 ? (
                  <Timeline
                    mode="left"
                    items={denouncement?.denouncement_item.map((item) => {
                      const color =
                        TIMELINE_COLOR[item.status as DenouncementStatus];
                      return {
                        color,
                        children: (
                          <Flex vertical gap={"small"}>
                            {dayjs(item.date).format("DD/MM/YYYY")}
                            <Text>{item.new_fact}</Text>
                          </Flex>
                        ),
                      };
                    })}
                  />
                ) : (
                  <Empty description="Nenhum item adicionado" />
                )}
              </Card>
            </Col>
          </Row>
        </Card>
      </Flex>
      <NewFactModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        handleSubmit={handleSubmit}
        form={form}
        statusOptions={Object.entries(DenouncementStatus).map(
          ([value, label]) => ({
            value,
            label,
          })
        )}
        handleDatePickerChange={handleDatePickerChange}
        currentStatus={currentStatus}
        updateStatus={updateStatus}
        loading={false}
      />
    </>
  );
};
