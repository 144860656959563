import { Card, Flex, List, Select, Space, Typography } from "antd";
import { useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useCampaign } from "../../../hooks/useCampaign";
import { useLocation, useParams } from "react-router-dom";
import { CampaignPetFilter } from "../../../../domain/entities/Campaign";
import {
  PetSpecies,
  PetRace,
  TypeOfHome,
} from "../../../../domain/entities/Pet";
import { UserContext } from "../../../../domain/context/user/UserContext";
import dayjs from "dayjs";
import { PetsList } from "../../../components/Campaign/Pets";

const { Text } = Typography;

export const Queue = () => {
  const location = useLocation();
  const [listType, setListType] = useState("queue");

  const userContext = useContext(UserContext);

  const filterReducer = (
    filter: CampaignPetFilter,
    info: { [key: string]: any }
  ) => ({
    ...filter,
    ...info,
  });

  const [filter, changeFilter] = useReducer(filterReducer, {
    neighborhood: "",
    pet_species: "",
    pet_race: "",
    pet_type_of_home: "",
    user_tutor_has_cadUnico: "",
    user_protector_is_protector: "",
  });

  const { id } = useParams();

  const {
    campaign,
    loading,
    actions: { handleGetCampaign },
  } = useCampaign();

  useEffect(() => {
    if (location.pathname.includes("/dashboard/campaign/schedule")) {
      setListType("schedule");
    }
  }, [location]);

  useEffect(() => {
    if (id) {
      handleGetCampaign(id);
    }
  }, [handleGetCampaign, id, listType]);

  const filteredLocations = useMemo(() => {
    return campaign?.campaign_locations
      .filter((campaignLocation) => {
        if (!filter.neighborhood) {
          return true;
        }
        return campaignLocation.neighborhood === filter.neighborhood;
      })
      .filter((campaignLocation) => {
        if (userContext.user?.scopes?.includes("campaign:pet:update_time")) {
          return true;
        }
        return dayjs().isSame(dayjs(campaignLocation.date), "day");
      });
  }, [campaign, filter, userContext]);

  return (
    <>
      <Card title={campaign?.name} className="w-full">
        {userContext.user?.scopes?.includes("campaign:create") && (
          <Flex gap="large" className="mb-4" wrap>
            <Flex vertical justify="flex-start" align="flex-start">
              <Text>Bairro</Text>
              <Space wrap>
                <Select
                  defaultValue={filter.neighborhood}
                  value={filter.neighborhood}
                  className="w-40"
                  onChange={(value) => changeFilter({ neighborhood: value })}
                  options={[
                    { value: "", label: "Todos" },
                    ...(campaign?.campaign_locations.map(
                      (campaignLocation) => ({
                        value: campaignLocation.neighborhood,
                        label: campaignLocation.neighborhood,
                      })
                    ) || []),
                  ]}
                />
              </Space>
            </Flex>
            <Flex vertical justify="flex-start" align="flex-start">
              <Text>Espécie</Text>
              <Space wrap>
                <Select
                  defaultValue={filter.pet_species}
                  value={filter.pet_species}
                  className="w-40"
                  onChange={(value) => changeFilter({ pet_species: value })}
                  options={[
                    { value: "", label: "Todos" },
                    ...(PetSpecies.map((specie) => ({
                      value: specie,
                      label: specie,
                    })) || []),
                  ]}
                />
              </Space>
            </Flex>
            <Flex vertical justify="flex-start" align="flex-start">
              <Text>Raça</Text>
              <Space wrap>
                <Select
                  defaultValue={filter.pet_race}
                  value={filter.pet_race}
                  className="w-40"
                  onChange={(value) => changeFilter({ pet_race: value })}
                  options={[
                    { value: "", label: "Todos" },
                    ...(PetRace.map((race) => ({
                      value: race,
                      label: race,
                    })) || []),
                  ]}
                />
              </Space>
            </Flex>
            <Flex vertical justify="flex-start" align="flex-start">
              <Text>Tipo de Moradia</Text>
              <Space wrap>
                <Select
                  defaultValue={filter.pet_type_of_home}
                  value={filter.pet_type_of_home}
                  className="w-40"
                  onChange={(value) =>
                    changeFilter({ pet_type_of_home: value })
                  }
                  options={[
                    { value: "", label: "Todos" },
                    ...(Object.entries(TypeOfHome).map(([key, value]) => ({
                      value: key,
                      label: value,
                    })) || []),
                  ]}
                />
              </Space>
            </Flex>
            <Flex vertical justify="flex-start" align="flex-start">
              <Text>Tem cadUnico</Text>
              <Space wrap>
                <Select
                  defaultValue={filter.user_tutor_has_cadUnico}
                  value={filter.user_tutor_has_cadUnico}
                  className="w-40"
                  onChange={(value) =>
                    changeFilter({ user_tutor_has_cadUnico: value })
                  }
                  options={[
                    { value: "", label: "Todos" },
                    { value: true, label: "Sim" },
                    { value: true, label: "Não" },
                  ]}
                />
              </Space>
            </Flex>
            <Flex vertical justify="flex-start" align="flex-start">
              <Text>É Protetor?</Text>
              <Space wrap>
                <Select
                  defaultValue={filter.user_protector_is_protector}
                  value={filter.user_protector_is_protector}
                  className="w-40"
                  onChange={(value) =>
                    changeFilter({ user_protector_is_protector: value })
                  }
                  options={[
                    { value: "", label: "Todos" },
                    { value: true, label: "Sim" },
                    { value: true, label: "Não" },
                  ]}
                />
              </Space>
            </Flex>
          </Flex>
        )}

        <List
          dataSource={filteredLocations}
          loading={loading}
          className="w-full"
          renderItem={(campaignLocation) => {
            const today = dayjs().startOf("day");
            const campaignDate = dayjs(campaignLocation.date).startOf("day");

            return (
              <List.Item data-testid="campaignLocation-item" className="w-full">
                {today.isBefore(campaignDate) && (
                  <PetsList
                    campaignLocation={campaignLocation}
                    listType={listType}
                    filter={filter}
                  />
                )}
              </List.Item>
            );
          }}
        />
      </Card>
    </>
  );
};
