import {
  Card,
  Flex,
  Tooltip,
  Typography,
  Form as AntForm,
  Table,
  Row,
  Col,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRequirement } from "../../../hooks/useRequirement";
import { Button } from "../../../components/Button";

import {
  IRequirementService,
  RequirementSeriviceStatus,
} from "../../../../domain/entities/Requirement";

import * as RequirementComponent from "../../../components/Requirement";
import { ROUTES } from "../../../Router";
import { TbExternalLink } from "react-icons/tb";
import { PetCard } from "../../../components/Cards/PetCard";
import { TutorCard } from "../../../components/Cards/TutorCard";
import { TableProps } from "antd/lib";

const { Text, Paragraph } = Typography;

export const Info: React.FC = () => {
  const {
    loading,
    requirement,
    actions: {
      handleGetRequirement,
      handlePatchUpdateStatus,
      handleRejectRequirement,
      handleConcludeRequirement,
    },
  } = useRequirement();

  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [form] = AntForm.useForm();

  const handleConfirm = useCallback(
    (values: any) => {
      if (requirement) {
        handleRejectRequirement(requirement.id, values.justification);
        navigate(ROUTES.DASHBOARD.REQUIREMENT.ROOT);
      }
    },
    [requirement, handleRejectRequirement, navigate]
  );

  const handleDispatch = useCallback(() => {
    if (requirement) {
      handlePatchUpdateStatus(requirement.id, "DISPATCHED");
      navigate(ROUTES.DASHBOARD.REQUIREMENT.ROOT);
    }
  }, [requirement, handlePatchUpdateStatus, navigate]);

  const handleSuspended = useCallback(() => {
    if (requirement) {
      handlePatchUpdateStatus(requirement.id, "SUSPENDED");
      navigate(ROUTES.DASHBOARD.REQUIREMENT.ROOT);
    }
  }, [requirement, handlePatchUpdateStatus, navigate]);

  const handleOpen = useCallback(() => {
    if (requirement) {
      handlePatchUpdateStatus(requirement.id, "OPEN");
      navigate(ROUTES.DASHBOARD.REQUIREMENT.ROOT);
    }
  }, [requirement, handlePatchUpdateStatus, navigate]);

  const handleClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const params = useParams();

  useEffect(() => {
    if (params.id) {
      handleGetRequirement(params.id);
    }
  }, [params, handleGetRequirement]);

  const columns: TableProps<IRequirementService>["columns"] = useMemo(
    () => [
      {
        title: "Serviço",
        dataIndex: "service",
        key: "service",
        render: (_: any, record) => (
          <Paragraph ellipsis={{ rows: 2 }}>
            {record.service.service_name}
          </Paragraph>
        ),
      },
      {
        title: "Quantidade Solicitada",
        dataIndex: "quantity",
        responsive: ["xl"],
        key: "quantity",
        width: 200,
        render: (_: any, record) => <Text>{record.quantity}</Text>,
      },

      {
        title: "Custo Unitário",
        dataIndex: "cost",
        key: "cost",
        responsive: ["xl"],
        width: 130,
        render: (_: any, record) => (
          <Text>R$ {record.cost ? record.cost.toFixed(2) : "-"}</Text>
        ),
      },

      {
        title: "Custo Total",
        dataIndex: "total_cost",
        key: "total_cost",
        responsive: ["sm"],
        width: 130,
        render: (_: any, record) => (
          <Text>R$ {(record.quantity * record.cost).toFixed(2)}</Text>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        responsive: ["xl"],
        width: 200,
        render: (_: any, record) =>
          record?.status === "INVOICE_UPLOADED" ? (
            <Link to={record.invoice_url as string} target="_blank">
              <Flex align="center">
                {RequirementSeriviceStatus[record?.status]}
                <TbExternalLink size={16} className="ml-1" />
              </Flex>
            </Link>
          ) : (
            <Text>{RequirementSeriviceStatus[record?.status]}</Text>
          ),
      },

      {
        title: "Disponibilidade",
        dataIndex: "available",
        key: "available",
        width: 200,
        render: (_: any, record) => (
          <Text>{record.available ? "Disponível" : "Indisponível"}</Text>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Flex
        vertical
        gap={"middle"}
        data-testid="requirement-view"
        className="w-full"
      >
        <Card
          loading={loading}
          title={
            <Flex justify="space-between" align="center">
              <Text>Requerimento {requirement?.protocol_number}</Text>
              {(requirement?.status === "OPEN" ||
                requirement?.status === "SUSPENDED") && (
                <Tooltip title="Indeferir Requerimento">
                  <Button
                    data-testid="reject-requirement"
                    status="danger"
                    onClick={() => setModalOpen(true)}
                  >
                    Indeferir
                  </Button>
                </Tooltip>
              )}
            </Flex>
          }
        >
          <Flex vertical gap="middle">
            <Row gutter={{ xs: 8, md: 16 }}>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Flex vertical gap="small">
                  <Text strong>Histórico</Text>
                  <Text>{requirement?.description}</Text>
                </Flex>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Flex vertical gap="small">
                  <Text strong>Sinais Clínicos</Text>
                  <Text>
                    {requirement?.clinical_signs
                      ? requirement.clinical_signs
                      : "Não especificado"}
                  </Text>
                </Flex>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Flex vertical gap="small">
                  <Text strong>Diagnósticos Diferenciais</Text>

                  <Text>{requirement?.differential_diagnosis}</Text>
                </Flex>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, md: 16 }}>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Flex vertical gap="small">
                  <Text strong>Destino: </Text>
                  <Text>{requirement?.destination}</Text>

                  {requirement?.destination === "Encaminhamento" && (
                    <Text>{requirement?.forwarding}</Text>
                  )}
                </Flex>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              {requirement?.ticket?.pet && (
                <Col xs={24} sm={24} md={12}>
                  <PetCard {...requirement.ticket.pet} />
                </Col>
              )}
              {requirement?.ticket?.pet?.tutor && (
                <Col xs={24} sm={24} md={12}>
                  <TutorCard {...requirement.ticket.pet.tutor} />
                </Col>
              )}
            </Row>

            <Table
              columns={columns}
              dataSource={requirement?.requirement_services}
              pagination={false}
              bordered
              size="small"
            />

            <Flex justify="flex-end" gap={"small"}>
              <Button
                htmlType="button"
                status="info"
                onClick={() => navigate(-1)}
              >
                Cancelar
              </Button>
              {requirement?.status === "OPEN" && (
                <Button
                  className="align-self-end"
                  status="success"
                  data-testid="dispatch-requirement"
                  onClick={handleDispatch}
                >
                  Despachar
                </Button>
              )}

              {requirement?.status === "AWAITING_CONCLUSION" && (
                <Button
                  className="align-self-end"
                  status="success"
                  onClick={() => handleConcludeRequirement(requirement.id)}
                >
                  Concluir Requerimento
                </Button>
              )}

              {requirement?.status === "OPEN" && (
                <Button
                  className="align-self-end"
                  status="success"
                  onClick={handleSuspended}
                >
                  Suspender
                </Button>
              )}
              {requirement?.status === "SUSPENDED" && (
                <Button
                  className="align-self-end"
                  status="success"
                  onClick={handleOpen}
                >
                  Abrir Requerimento
                </Button>
              )}
            </Flex>
          </Flex>
        </Card>
      </Flex>
      <RequirementComponent.RejectModal
        open={modalOpen}
        form={form}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </>
  );
};
