import { IUser } from "../../domain/entities/User";
import { api } from ".";
import { Cookies } from "react-cookie";

export async function signIn(user: {
  email: string;
  password: string;
}): Promise<IUser | undefined> {
  return await api.post("/api/v1/auth/login", user);
}

export async function revokeAccessToken(): Promise<void> {
  await api.delete("/api/v1/auth/revoke_access");
}

export async function revokeRefreshToken(): Promise<void> {
  const cookies = new Cookies();

  await api.delete("/api/v1/auth/refresh/revoke_refresh", {
    headers: {
      "X-CSRF-TOKEN-REFRESH": cookies.get("csrf_refresh_token"),
    },
  });
}

export async function refreshToken(): Promise<void> {
  const cookies = new Cookies();
  await api.get("/api/v1/auth/refresh", {
    headers: {
      "X-CSRF-TOKEN-REFRESH": cookies.get("csrf_refresh_token"),
    },
  });
}
