import {
  Card,
  Flex,
  List,
  Modal,
  Typography,
  Input,
  Space,
  Select,
  Table,
  TableProps,
  Tooltip,
} from "antd";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useStock } from "../../../hooks/useStock";
import { Button } from "../../../components/Button";
import { UserContext } from "../../../../domain/context/user/UserContext";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../../../Router";
import { IItemsServices, ItemType } from "../../../../domain/entities/Item";
import { useConfig } from "../../../hooks/useConfig";
import { LuPackagePlus } from "react-icons/lu";

const { Paragraph, Text } = Typography;

export const Stock: React.FC = () => {
  const userContext = useContext(UserContext);
  const [openAllocationModal, setOpenAllocationModal] = useState(false);
  const [allocated, setAllocated] = useState({} as IItemsServices);
  const [newAllocate, setNewAllocate] = useState(0);

  const config = useConfig();

  const {
    loading,
    stockList,
    actions: { handleGetStock, handlePutAllocation },
  } = useStock();

  const filterReducer = (filter: any, info: any) => ({
    ...filter,
    ...info,
  });

  const [filter, changeFilter] = useReducer(filterReducer, {
    service: "",
    type: "",
    searchIdentifier: "",
  });

  const filterItem = useMemo(() => {
    if (!stockList) {
      return [];
    }
    return stockList
      ?.filter((stock) =>
        stock.order_id
          .toLowerCase()
          .includes(filter.searchIdentifier.toLowerCase())
      )
      .filter((stock) => {
        if (filter.type === "") return true;

        return stock.type.toLowerCase().startsWith(filter.type.toLowerCase());
      })
      .filter((stock) => {
        if (filter.service === "") {
          return true;
        }
        return stock.items_services.some(
          (service) => service.service.id === filter.service
        );
      });
  }, [stockList, filter]);

  useEffect(() => {
    handleGetStock();
  }, [handleGetStock]);

  const handleAllocate = useCallback((currentAllocated: IItemsServices) => {
    setAllocated(currentAllocated);
    setNewAllocate(currentAllocated.allocation?.allocated ?? 0);
    setOpenAllocationModal(true);
  }, []);

  const handleSubmit = useCallback(async () => {
    await handlePutAllocation(allocated.id, newAllocate);
    setOpenAllocationModal(false);
    setAllocated({} as IItemsServices);
    setNewAllocate(0);
    await handleGetStock();
  }, [allocated, newAllocate, handlePutAllocation, handleGetStock]);

  const columns: TableProps<IItemsServices>["columns"] = useMemo(
    () => [
      {
        title: "Serviço",
        dataIndex: "service_name",
        key: "service_name",
        render: (_: any, record) => (
          <Paragraph ellipsis={{ rows: 2 }} title={record.service.service_name}>
            {record.service.service_name}
          </Paragraph>
        ),
      },
      {
        title: "Total",
        dataIndex: "quantity",
        key: "quantity",
        responsive: ["xl"],
        render: (_: any, record) => <Text>{record.quantity}</Text>,
      },
      {
        title: "Alocados",
        dataIndex: "allocation.allocated",
        key: "allocation.allocated",
        responsive: ["md"],
        render: (_: any, record) => <Text>{record.allocation?.allocated}</Text>,
      },
      {
        title: "Usados",
        dataIndex: "allocation.used",
        key: "allocation.used",
        render: (_: any, record) => {
          if (!record.allocation) {
            return <Text className="font-bold">0</Text>;
          }

          const checkQuantity =
            (record.allocation.used * 100) / record.allocation.allocated;

          let color = "text-green-600";
          if (checkQuantity > 66) {
            color = "text-red";
          } else if (checkQuantity > 33) {
            color = "text-yellow";
          }

          return (
            <Text className={`${color} font-bold`}>
              {record.allocation?.used}
            </Text>
          );
        },
      },

      {
        title: "Custo Unitário",
        dataIndex: "cost",
        key: "cost",
        width: 130,
        responsive: ["xl"],
        render: (_: any, record) => (
          <Text>R$ {record.unit_price?.toFixed(2) ?? "-"}</Text>
        ),
      },

      {
        title: "Custo Usado",
        dataIndex: "used_cost",
        key: "used_cost",
        width: 130,
        responsive: ["sm"],
        render: (_: any, record) => (
          <Text>
            R${" "}
            {(record.unit_price * (record.allocation?.used ?? 0)).toFixed(2) ??
              "-"}
          </Text>
        ),
      },

      {
        title: "Ações",
        dataIndex: "actions",
        key: "actions",
        width: 80,
        render: (_: any, record) => {
          const item = stockList.filter((stock) =>
            stock.items_services.includes(record)
          )[0];

          if (
            !userContext.user?.scopes?.includes("item:stock:allocate") ||
            item.type !== ItemType.LICITATION
          ) {
            return <Text className="opacity-30">Sem ações</Text>;
          }

          return (
            <Space size="small">
              <Tooltip title="Empenhar">
                <Button
                  className="p-2"
                  type="text"
                  data-testid="allocate-item"
                  onClick={() => handleAllocate(record)}
                >
                  <LuPackagePlus size={18} />
                </Button>
              </Tooltip>
            </Space>
          );
        },
      },
    ],
    [handleAllocate, userContext.user?.scopes, stockList]
  );

  if (!userContext.user?.scopes?.includes("item:stock:read")) {
    return <Navigate to={ROUTES.DASHBOARD.ROOT} />;
  }

  return (
    <Card title="Estoque" className="w-full">
      <Flex gap="large" className="mb-4" wrap>
        <Flex vertical gap={2} justify="flex-start" align="flex-start">
          <Text>Pesquisar Item</Text>
          <Input
            placeholder="Digite o identificador"
            value={filter.searchIdentifier}
            onChange={(e) => changeFilter({ searchIdentifier: e.target.value })}
            className="ml-2 w-60"
          />
        </Flex>
        <Flex vertical gap={2} justify="flex-start" align="flex-start">
          <Text>Tipo</Text>
          <Space wrap>
            <Select
              defaultValue={filter.type}
              className="w-60"
              onChange={(value) => changeFilter({ type: value })}
              options={[
                { value: "", label: "Todos" },
                { value: "LICITATION", label: "Licitação" },
                { value: "DIRECT_BUY", label: "Compra Direta" },
              ]}
            />
          </Space>
        </Flex>
        <Flex vertical gap={2} justify="flex-start" align="flex-start">
          <Text>Serviços</Text>
          <Space wrap>
            <Select
              defaultValue={filter.service}
              className="w-60"
              onChange={(value) => changeFilter({ service: value })}
              options={[
                { value: "", label: "Todos" },
                ...config.services.map((service) => ({
                  label: service.service_name,
                  value: service.id,
                })),
              ]}
            />
          </Space>
        </Flex>
      </Flex>
      <List
        dataSource={filterItem}
        loading={loading}
        className="w-full"
        renderItem={(stock) => {
          return (
            <List.Item data-testid="stock-item" className="w-full">
              <Card
                title={`${
                  stock.type === ItemType.LICITATION
                    ? "Licitação"
                    : "Compra Direta"
                } - ${stock.order_id}`}
                className="w-full"
              >
                <Table
                  columns={columns}
                  dataSource={stock.items_services}
                  pagination={false}
                />
              </Card>
            </List.Item>
          );
        }}
      />
      <Modal
        open={openAllocationModal}
        centered
        cancelText="Cancelar"
        okText="Confirmar"
        title="Empenhar"
        data-testid="allocate-modal"
        onCancel={() => setOpenAllocationModal(false)}
        footer={[
          <Button
            key="back"
            status="danger"
            onClick={() => setOpenAllocationModal(false)}
          >
            Cancelar
          </Button>,
          <Button
            status="success"
            key="submit"
            onClick={handleSubmit}
            data-testid="send-button-modal"
            loading={false}
          >
            Enviar
          </Button>,
        ]}
      >
        <Flex vertical gap={"small"}>
          <Text>
            <Text className="font-bold">Total: </Text>
            {allocated.quantity ?? 0}
          </Text>

          <Text>
            <Text className="font-bold">Usados: </Text>
            {allocated.allocation?.used ?? 0}
          </Text>

          <Text>
            <Text className="font-bold">Custo Unitário: </Text>
            R$ {allocated.unit_price?.toFixed(2) ?? 0}
          </Text>

          <label>
            <Text>Quantidade que será alocada</Text>
            <Input
              placeholder="Quantidade"
              type="number"
              min={0}
              value={newAllocate}
              onChange={(e) => setNewAllocate(Number(e.target.value))}
            />
          </label>

          <Text>
            <Text className="font-bold">Valor sendo alocado: </Text>
            R$ {(allocated.unit_price * newAllocate)?.toFixed(2) ?? 0}
          </Text>
        </Flex>
      </Modal>
    </Card>
  );
};
