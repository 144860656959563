import {
  Typography,
  Flex,
  Input,
  Table,
  Card,
  Space,
  Form as AntForm,
  Select,
  TableProps,
  Row,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Router";
import { Button } from "../../../components/Button";
import { useContext, useEffect, useMemo, useReducer } from "react";

import { UserContext } from "../../../../domain/context/user/UserContext";
import { FaEye, FaPencilAlt } from "react-icons/fa";
import { useRequirement } from "../../../hooks/useRequirement";
import {
  IRequirement,
  RequirementStatus,
} from "../../../../domain/entities/Requirement";
import { useVeterinarian } from "../../../hooks/useVeterinarian";

const { Text } = Typography;

export const List: React.FC = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const {
    veterinarianList,
    actions: { handleGetAllVeterinarians },
  } = useVeterinarian();

  const {
    loading,
    requirementList,
    actions: { handleGetAllRequirements },
  } = useRequirement();

  const filterReducer = (filter: any, info: any) => ({
    ...filter,
    ...info,
  });

  const [filter, changeFilter] = useReducer(filterReducer, {
    searchIdentifier: "",
    veterinarian: "",
    status: "",
  });

  const columns: TableProps<IRequirement>["columns"] = useMemo(
    () => [
      {
        title: "Número do Protocolo",
        dataIndex: "protocol_number",
        key: "protocol_number",
        ellipsis: {
          showTitle: false,
        },
        render: (_: any, record: IRequirement) => (
          <Text>{record.protocol_number}</Text>
        ),
      },

      {
        title: "Veterinário",
        dataIndex: "veterinary",
        key: "veterinary",
        responsive: ["lg"],
        render: (_: any, record: IRequirement) => (
          <Text>{record.veterinarian?.user.full_name}</Text>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_: any, record: IRequirement) => (
          <Text>
            {RequirementStatus[record.status as keyof typeof RequirementStatus]}
          </Text>
        ),
      },
      {
        title: "Ações",
        dataIndex: "actions",
        key: "actions",
        width: 120,
        render: (_: any, record: IRequirement) => {
          return (
            <Space size="small">
              {userContext.user?.scopes?.includes("requirement:read") && (
                <Button
                  className="p-2"
                  type="text"
                  data-testid="view-requirement"
                  onClick={() =>
                    navigate(
                      ROUTES.DASHBOARD.REQUIREMENT.INFO.replace(
                        ":id",
                        record.id
                      )
                    )
                  }
                >
                  <FaEye size={16} />
                </Button>
              )}
              {record.status === "OPEN" && (
                <>
                  {userContext.user?.scopes?.includes("requirement:read") && (
                    <Button
                      className="p-2"
                      type="text"
                      data-testid="edit-requirement"
                      onClick={() =>
                        navigate(
                          ROUTES.DASHBOARD.REQUIREMENT.EDIT.replace(
                            ":id",
                            record.id
                          )
                        )
                      }
                    >
                      <FaPencilAlt size={16} />
                    </Button>
                  )}
                </>
              )}
            </Space>
          );
        },
      },
    ],
    [navigate, userContext.user?.scopes]
  );

  const filterRequirements = useMemo(() => {
    if (!requirementList) {
      return [];
    }
    return requirementList
      ?.filter((requirementsFilter) =>
        requirementsFilter.protocol_number
          .toString()
          .includes(filter.searchIdentifier.toLowerCase())
      )
      .filter((requirementsFilter) =>
        filter.veterinarian !== ""
          ? requirementsFilter.veterinarian_id === filter.veterinarian
          : true
      )
      .filter((requirementsFilter) =>
        filter.status !== ""
          ? requirementsFilter.status === filter.status
          : true
      );
  }, [requirementList, filter]);

  useEffect(() => {
    handleGetAllRequirements();

    if (userContext.user?.scopes?.includes("requirement:create:vet_id")) {
      handleGetAllVeterinarians();
    }
  }, [handleGetAllRequirements, handleGetAllVeterinarians, userContext]);

  return (
    <>
      <Card className="w-full">
        <Flex vertical gap="middle">
          <Row>
            <Flex
              gap={10}
              wrap="wrap"
              justify="space-between"
              align="centers"
              className="w-full"
            >
              <Flex gap="large">
                <AntForm>
                  <Flex
                    vertical
                    gap={2}
                    justify="flex-start"
                    align="flex-start"
                  >
                    <Text>Pesquisar</Text>
                    <Input
                      placeholder="Pesquisar..."
                      value={filter.searchIdentifier}
                      onChange={(e) =>
                        changeFilter({ searchIdentifier: e.target.value })
                      }
                    />
                  </Flex>
                </AntForm>
                {userContext.user?.scopes?.includes(
                  "requirement:create:vet_id"
                ) && (
                  <Flex
                    vertical
                    justify="flex-start"
                    align="flex-start"
                    wrap="wrap"
                  >
                    <Text>Veterinario</Text>
                    <Space wrap>
                      <Select
                        showSearch
                        size="middle"
                        className="!w-48"
                        defaultValue={filter.veterinarian}
                        placeholder="Selecione um veterinário"
                        onChange={(value) =>
                          changeFilter({ veterinarian: value })
                        }
                        options={[
                          { value: "", label: "Todos" },
                          ...veterinarianList.map((veterinarian) => {
                            return {
                              label: veterinarian.user.full_name,
                              value: veterinarian.id,
                            };
                          }),
                        ]}
                      />
                    </Space>
                  </Flex>
                )}
                <Flex vertical justify="flex-start" align="flex-start">
                  <Text>Status</Text>
                  <Space wrap>
                    <Select
                      showSearch
                      defaultValue={filter.status}
                      className="w-40"
                      onChange={(value) => changeFilter({ status: value })}
                      options={[
                        { value: "", label: "Todos" },
                        { value: "OPEN", label: "Aberto" },
                        { value: "REJECTED", label: "Indeferido" },
                        { value: "DISPATCHED", label: "Dispachado" },
                        { value: "SUSPENDED", label: "Suspenso" },
                        {
                          value: "AWAITING_CONCLUSION",
                          label: "Aguardando Conclusão",
                        },
                        { value: "CONCLUDED", label: "Concluído" },
                      ]}
                    />
                  </Space>
                </Flex>
              </Flex>
              <Flex gap="small" wrap="wrap" justify="flex-end" align="end">
                <Link to={ROUTES.DASHBOARD.REQUIREMENT.ADD}>
                  <Button status="info">Novo Requerimento</Button>
                </Link>
              </Flex>
            </Flex>
          </Row>
          <Table
            className="w-full border rounded"
            columns={columns}
            dataSource={filterRequirements}
            pagination={{ pageSize: 8 }}
            loading={loading}
            rowKey={(row) => row.id}
          />
        </Flex>
      </Card>
    </>
  );
};
