import { useContext, useEffect, useMemo, useState } from "react";
import { Flex, Input, Space, Table, Typography, Card, TableProps } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Router";
import { Button } from "../../../components/Button";
import { FaEye } from "react-icons/fa";
import { UserContext } from "../../../../domain/context/user/UserContext";
import { IProtectorInterview } from "../../../../domain/entities/Protector";
import { useProtector } from "../../../hooks/useProtector";
const { Text } = Typography;

export const ProtectorList: React.FC = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState("");

  const {
    loading,
    protectorList,
    actions: { handleGetAllProtectors },
  } = useProtector();

  const columns: TableProps<IProtectorInterview>["columns"] = useMemo(
    () => [
      {
        title: "Usuários",
        dataIndex: "full_name",
        key: "full_name",
        ellipsis: {
          showTitle: false,
        },
        width: 180,
        render: (_: any, record: IProtectorInterview) => (
          <Text>{record.tutor.user.full_name}</Text>
        ),
      },
      {
        title: "Telefone",
        dataIndex: "phone",
        key: "phone",
        ellipsis: {
          showTitle: false,
        },
        responsive: ["md"],
        render: (_: any, record: IProtectorInterview) => (
          <Text>
            {" "}
            {record.tutor.user.phone.replace(
              /^(\d{2})(\d{5})(\d{4})$/,
              "($1) $2-$3"
            )}
          </Text>
        ),
      },
      {
        title: "E-mail",
        dataIndex: "email",
        key: "email",
        ellipsis: {
          showTitle: false,
        },
        responsive: ["lg"],
        render: (_: any, record: IProtectorInterview) => (
          <Text>{record.tutor.user.email}</Text>
        ),
      },
      {
        title: "Endereço",
        dataIndex: "address",
        key: "address",
        ellipsis: {
          showTitle: false,
        },
        responsive: ["xl"],
        render: (_: any, record: IProtectorInterview) => (
          <Text>{record.tutor.user.address}</Text>
        ),
      },

      {
        title: "Ações",
        dataIndex: "actions",
        key: "actions",
        width: 80,
        render: (_: any, record: IProtectorInterview) => {
          return (
            <Space size="small">
              {userContext.user?.scopes?.includes("protector:read") && (
                <Button
                  className="p-2"
                  type="text"
                  data-testid="view-protector"
                  onClick={() =>
                    navigate(
                      ROUTES.DASHBOARD.PROTECTOR.INFO.replace(":id", record.id)
                    )
                  }
                >
                  <FaEye size={18} />
                </Button>
              )}
            </Space>
          );
        },
      },
    ],
    [navigate, userContext]
  );

  const filterProtector = useMemo(() => {
    if (!protectorList) {
      return [];
    }
    return protectorList?.filter((protectorFilter) =>
      protectorFilter.tutor.user.full_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  }, [protectorList, searchTerm]);

  useEffect(() => {
    handleGetAllProtectors();
  }, [handleGetAllProtectors]);

  return (
    <>
      <Card className="w-full">
        <Flex vertical gap="middle">
          <Flex justify="space-between" align="centers">
            <Flex vertical gap={5} justify="flex-start" align="flex-start">
              <Text>Pesquisar</Text>
              <Input
                placeholder="Digite o nome do Protetor"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="ml-2"
              />
            </Flex>
          </Flex>

          <Table
            className="w-full border rounded"
            columns={columns}
            dataSource={filterProtector}
            pagination={{ pageSize: 8 }}
            loading={loading}
            rowKey={(row) => row.id}
          />
        </Flex>
      </Card>
    </>
  );
};
