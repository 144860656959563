import { Card, Col, Flex, Row, Select, Spin, Typography } from "antd";
import React, { useMemo, useState } from "react";
import { IDashboard } from "../../../domain/entities/Dashboard";
import { TicketStatus } from "../../../domain/entities/Ticket";
import { Chart } from "./Chart";
import { Chart as ChartJS, registerables } from "chart.js";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

const { Text } = Typography;

ChartJS.register(...registerables);

const CHART_TYPES = {
  bar: "Barra",
  pie: "Pizza",
};

const CHART_MONTHS = [3, 5, 12];

type ChartsProps = {
  loading: boolean;
  isPrint?: boolean;
  dashboard?: IDashboard;
  selectedMonth: number;
  setSelectedMonth: (value: number) => void;
};

export const ChartsSection: React.FC<ChartsProps> = ({
  loading,
  dashboard,
  isPrint = false,
  selectedMonth,
  setSelectedMonth,
}) => {
  const [selectedChart, setSelectedChart] =
    useState<keyof typeof CHART_TYPES>("pie");

  const months = useMemo(() => {
    const timelineMonths = new Array(selectedMonth).fill(0).map((_, index) => {
      const date = dayjs().subtract(selectedMonth - index - 1, "month");
      return date.format("MM/YYYY");
    });

    return timelineMonths;
  }, [selectedMonth]);

  const getTicketKeys = useMemo(
    () =>
      Object.keys(dashboard?.tickets || {}).filter((key) =>
        Object.keys(TicketStatus).includes(key.toUpperCase() as TicketStatus)
      ),
    [dashboard?.tickets]
  );

  const getPetSpecies = useMemo(
    () =>
      dashboard?.denouncements.denouncements_by_species.map(
        (value) => value.species
      ),
    [dashboard?.denouncements.denouncements_by_species]
  );

  const denouncementTimeline = useMemo(
    () => dashboard?.denouncements.created_by_month.slice().reverse() || [],
    [dashboard?.denouncements]
  );

  const ticketTimeline = useMemo(
    () => dashboard?.tickets.created_by_month.slice().reverse() || [],
    [dashboard?.tickets]
  );

  const denouncementByGravityTimeline = useMemo(
    () =>
      Object.entries(
        dashboard?.denouncements.created_by_month_and_gravity || {}
      ).map(([key, value]) => ({
        data: value
          .slice()
          .reverse()
          .map((value) => value.count),
        label: key,
      })) || [],
    [dashboard?.denouncements]
  );

  const ticketBySpeciesTimeline = useMemo(
    () =>
      Object.entries(dashboard?.tickets.created_by_month_and_species || {}).map(
        ([key, value]) => ({
          data: value
            .slice()
            .reverse()
            .map((value) => value.count),
          label: key,
        })
      ) || [],
    [dashboard?.tickets]
  );

  return (
    <>
      <Text className="text-lg font-bold">Gráficos</Text>
      <Spin spinning={loading}>
        <Card>
          <Flex gap={"large"} vertical>
            <Row className="print:hidden">
              <Col span={24}>
                <Flex gap={"large"} align="center">
                  <Text>Tipo dos Gráficos</Text>
                  <Select
                    className="w-1/5"
                    defaultValue={selectedChart}
                    value={selectedChart}
                    onChange={(value) => {
                      setSelectedChart(value);
                    }}
                    options={Object.entries(CHART_TYPES).map(
                      ([key, value]) => ({
                        value: key,
                        label: value,
                      })
                    )}
                  />
                </Flex>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Chart
                responsive={{ xs: isPrint ? 8 : 24, lg: 12, xl: 8 }}
                type={selectedChart}
                labels={["Castrados", "Microchipados"]}
                data={[
                  {
                    data: [
                      dashboard?.pets.castrated || 0,
                      dashboard?.pets.microchipped || 0,
                    ],
                  },
                ]}
                options={
                  selectedChart === "bar"
                    ? {
                        scales: {
                          y: { beginAtZero: true, ticks: { stepSize: 1 } },
                        },
                      }
                    : {}
                }
                title="Pets Castrados vs Microchipados"
              />

              <Chart
                responsive={{ xs: isPrint ? 8 : 24, lg: 12, xl: 8 }}
                title="Chamados"
                type={selectedChart}
                labels={getTicketKeys.map(
                  (value) =>
                    TicketStatus[
                      value.toUpperCase() as keyof typeof TicketStatus
                    ]
                )}
                options={
                  selectedChart === "bar"
                    ? {
                        scales: {
                          y: { beginAtZero: true, ticks: { stepSize: 1 } },
                        },
                      }
                    : {}
                }
                data={[
                  {
                    data: getTicketKeys.map(
                      (value) =>
                        dashboard?.tickets[
                          value as keyof typeof dashboard.tickets
                        ]
                    ) as number[],
                  },
                ]}
              />

              <Chart
                responsive={{ xs: isPrint ? 8 : 24, lg: 12, xl: 8 }}
                title="Pets em lar temporário vs permanente"
                type={selectedChart}
                labels={["Lar temporário", "Permanente"]}
                options={
                  selectedChart === "bar"
                    ? {
                        scales: {
                          y: { beginAtZero: true, ticks: { stepSize: 1 } },
                        },
                      }
                    : {}
                }
                data={[
                  {
                    data: [
                      dashboard?.pets.in_temporary_home || 0,
                      dashboard?.pets.in_permanent_home || 0,
                    ],
                  },
                ]}
              />

              <Chart
                responsive={{ xs: isPrint ? 8 : 24, lg: 12, xl: 8 }}
                title="Protetores vs Tutores"
                type={selectedChart}
                labels={["Protetores", "Tutores"]}
                options={
                  selectedChart === "bar"
                    ? {
                        scales: {
                          y: { beginAtZero: true, ticks: { stepSize: 1 } },
                        },
                      }
                    : {}
                }
                data={[
                  {
                    data: [
                      dashboard?.users.tutors || 0,
                      dashboard?.users.protectors || 0,
                    ],
                  },
                ]}
              />

              <Chart
                responsive={{ xs: isPrint ? 8 : 24, lg: 12, xl: 8 }}
                title="Chamados Abertos por Espécie"
                type={selectedChart}
                labels={getPetSpecies || []}
                options={
                  selectedChart === "bar"
                    ? {
                        scales: {
                          y: { beginAtZero: true, ticks: { stepSize: 1 } },
                        },
                      }
                    : {}
                }
                data={[
                  {
                    data:
                      dashboard?.denouncements.denouncements_by_species.map(
                        (value) => value.total
                      ) || [],
                  },
                ]}
              />
            </Row>
          </Flex>
        </Card>
      </Spin>

      <Row className="print:mt-72" />

      <Text className="text-lg font-bold">Linha do Tempo</Text>
      <Spin spinning={loading}>
        <Card>
          <Flex gap={"large"} vertical>
            <Row>
              <Col span={24}>
                <Flex gap={"large"} align="center">
                  <Text>Tempo</Text>

                  {isPrint ? (
                    <Text>
                      {selectedMonth} {selectedMonth === 1 ? "mês" : "meses"}
                    </Text>
                  ) : (
                    <Select
                      className="w-1/5"
                      defaultValue={selectedMonth}
                      value={selectedMonth}
                      onChange={setSelectedMonth}
                      options={CHART_MONTHS.map((value) => ({
                        value,
                        label: `${value} ${value === 1 ? "mês" : "meses"}`,
                      }))}
                    />
                  )}
                </Flex>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Chart
                responsive={{ xs: isPrint ? 12 : 24, lg: 12 }}
                type="line"
                title="Denúncias"
                data={[
                  {
                    data: denouncementTimeline?.map((value) => value.count),
                    label: "Denúncias",
                  },
                ]}
                labels={months}
                options={{
                  scales: {
                    y: {
                      min: 0,
                      ticks: {
                        stepSize: 1,
                      },
                    },
                  },
                }}
              />
              <Chart
                responsive={{ xs: isPrint ? 12 : 24, lg: 12 }}
                title="Chamados Abertos"
                type="line"
                labels={months}
                data={[
                  {
                    data: ticketTimeline?.map((value) => value.count),
                    label: "Chamados em Aberto",
                  },
                ]}
                options={{
                  scales: {
                    y: {
                      min: 0,
                      ticks: {
                        stepSize: 1,
                      },
                    },
                  },
                }}
              />
              <Chart
                responsive={{ xs: isPrint ? 12 : 24, lg: 12 }}
                title="Denúncias por Gravidade"
                type="line"
                labels={months}
                data={denouncementByGravityTimeline}
                options={{
                  scales: {
                    y: {
                      min: 0,
                      ticks: {
                        stepSize: 1,
                      },
                    },
                  },
                }}
              />

              <Chart
                responsive={{ xs: isPrint ? 12 : 24, lg: 12 }}
                title="Chamados por Espécie"
                type="line"
                labels={months}
                data={ticketBySpeciesTimeline}
                options={{
                  scales: {
                    y: {
                      min: 0,
                      ticks: {
                        stepSize: 1,
                      },
                    },
                  },
                }}
              />
            </Row>
          </Flex>
        </Card>
      </Spin>
    </>
  );
};
