import axios, { AxiosInstance } from "axios";
import { Cookies } from "react-cookie";
import { refreshToken } from "./auth";
import { toast } from "react-toastify";
import { createBrowserHistory } from "history";
import { ROUTES } from "../../presentation/Router";

const cookies = new Cookies();

export let api: AxiosInstance;

export let apiLocal: AxiosInstance;

export function initializeAxios() {
  api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      bdd809775f: "f468c5b3d0",
    },
  });

  api.interceptors.request.use((config) => {
    const token = cookies.get("csrf_access_token");

    if (token) {
      config.headers["X-CSRF-TOKEN"] = token;
    }
    return config;
  });

  api.interceptors.response.use(
    (response) => response.data.data,
    async (error) => {
      if (
        error.response.status === 401 &&
        error.response.data.message === "The token has expired"
      ) {
        await refreshToken();

        return api.request(error.config);
      }

      if (
        error.response.status === 401 &&
        error.response.data.message === "The refresh token has expired"
      ) {
        const history = createBrowserHistory();

        localStorage.removeItem("user");

        setTimeout(() => {
          history.replace(ROUTES.ROOT);

          window.location.reload();
        }, 5500);

        return Promise.reject();
      }

      if (
        error.response.status === 401 &&
        error.response.data.message === "Token is missing"
      ) {
        const history = createBrowserHistory();

        localStorage.removeItem("user");

        history.replace(ROUTES.ROOT);

        window.location.reload();
      }

      if (error.response.status === 403) {
        toast.error("Você não tem permissão para realizar esta ação");
      } else {
        toast.error(error.response.data.message);
      }

      return Promise.reject(error);
    }
  );
}

export function initializeAxiosLocal() {
  apiLocal = axios.create({
    baseURL: "http://localhost:8000",
    withCredentials: false,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  apiLocal.interceptors.response.use((response) => response.data);
}
