import { Col, Flex, Row, Spin, Typography } from "antd";
import { IDashboard } from "../../../domain/entities/Dashboard";
import { Button } from "../Button";

const { Text } = Typography;

type MetricsProps = {
  dashboard?: IDashboard;
  loading: boolean;
  isPrint?: boolean;
  selectedNeighborhood: string;
  setSelectedNeighborhood: (value: string) => void;
};

export const Metrics: React.FC<MetricsProps> = ({
  dashboard,
  loading,
  isPrint = false,
  selectedNeighborhood,
  setSelectedNeighborhood,
}) => {
  return (
    <Row gutter={[16, 50]}>
      <Col span={24}>
        <Flex align="center">
          <Text className="font-bold text-lg">
            Métricas{" "}
            <span className="capitalize">
              {selectedNeighborhood ? `- ${selectedNeighborhood}` : "Gerais"}
            </span>
          </Text>
          {!isPrint && selectedNeighborhood && (
            <Button
              className="ml-auto"
              onClick={() => setSelectedNeighborhood("")}
            >
              Limpar
            </Button>
          )}
        </Flex>
      </Col>
      <Col span={24}>
        <Spin spinning={loading}>
          <Flex vertical gap={"large"}>
            <Flex wrap="wrap" gap={"middle"}>
              <span>
                <Text className="font-bold">Total de Pets: </Text>
                <Text>{dashboard?.pets.total}</Text>
              </span>
              <span>
                <Text className="font-bold">Castrados: </Text>
                <Text>{dashboard?.pets.castrated}</Text>
              </span>
              <span>
                <Text className="font-bold">Microchipados: </Text>
                <Text>{dashboard?.pets.microchipped}</Text>
              </span>
            </Flex>

            <Flex wrap="wrap" gap={"middle"}>
              <span>
                <Text className="font-bold">Total de Denúncias: </Text>
                <Text>{dashboard?.denouncements.total}</Text>
              </span>
              <span>
                <Text className="font-bold">Abertas: </Text>
                <Text>{dashboard?.denouncements.open}</Text>
              </span>
              <span>
                <Text className="font-bold">Em Andamento: </Text>
                <Text>{dashboard?.denouncements.on_going}</Text>
              </span>
              <span>
                <Text className="font-bold">Fechados: </Text>
                <Text>{dashboard?.denouncements.closed}</Text>
              </span>
            </Flex>

            <Flex wrap="wrap" gap={"middle"}>
              <span>
                <Text className="font-bold">Total de Campanhas: </Text>
                <Text>{dashboard?.campaigns.total}</Text>
              </span>
              <span>
                <Text className="font-bold">Atendidos: </Text>
                <Text>{dashboard?.campaigns.pets_attended}</Text>
              </span>
              <span>
                <Text className="font-bold">Castrados: </Text>
                <Text>{dashboard?.campaigns.pets_castrated}</Text>
              </span>
              <span>
                <Text className="font-bold">Microchipados: </Text>
                <Text>{dashboard?.campaigns.pets_microchipped}</Text>
              </span>
            </Flex>

            <Flex wrap="wrap" gap={"middle"}>
              <span>
                <Text className="font-bold">Total Gastos: </Text>
                <Text>{dashboard?.expenses.total}</Text>
              </span>
              <span>
                <Text className="font-bold">Na Campanha: </Text>
                <Text>{dashboard?.expenses.on_campaigns}</Text>
              </span>
              <span>
                <Text className="font-bold">No Requerimento: </Text>
                <Text>{dashboard?.expenses.on_requirements}</Text>
              </span>
            </Flex>

            <Flex wrap="wrap" gap={"middle"}>
              <span>
                <Text className="font-bold">Total de Chamados: </Text>
                <Text>{dashboard?.tickets.total}</Text>
              </span>
              <span>
                <Text className="font-bold">Abertos: </Text>
                <Text>{dashboard?.tickets.open}</Text>
              </span>
              <span>
                <Text className="font-bold">Em Andamento: </Text>
                <Text>{dashboard?.tickets.on_going}</Text>
              </span>
              <span>
                <Text className="font-bold">Fechados: </Text>
                <Text>{dashboard?.tickets.closed}</Text>
              </span>
              <span>
                <Text className="font-bold">Negado: </Text>
                <Text>{dashboard?.tickets.denied}</Text>
              </span>
            </Flex>
          </Flex>
        </Spin>
      </Col>
    </Row>
  );
};
