import {
  Modal as AntModal,
  Flex,
  Typography,
  Form as AntForm,
  Input,
  FormInstance,
  Upload,
} from "antd";
import React from "react";
import { Button } from "../Button";

const { Text } = Typography;

type RequirementModalProps = {
  open: boolean;
  form: FormInstance;
  onClose: () => void;
  onConfirm: (values: any) => void;
};

export const ConfirmServiceModal: React.FC<RequirementModalProps> = ({
  open,
  form,
  onClose,
  onConfirm,
}) => {
  return (
    <AntModal
      title="Serviço concluido"
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          onClick={form.submit}
          data-testid="send-button-modal"
          loading={false}
          status="success"
        >
          Enviar
        </Button>,
      ]}
    >
      <Flex vertical gap={"middle"}>
        <Text>Adicione a Nota Fiscal e a quantidade do serviço finalizado</Text>
        <AntForm layout="vertical" onFinish={onConfirm} form={form}>
          <AntForm.Item
            name="quantity_consumed"
            label="Quantidade"
            rules={[
              {
                required: true,
                message: "Quantidade obrigatória",
              },
              {
                min: 0,
                message: "Quantidade deve ser maior ou igual a zero",
              },
            ]}
          >
            <Input type="number" />
          </AntForm.Item>
          <AntForm.Item
            name={"invoice_url"}
            rules={[
              {
                required: true,
                message: "Nota fiscal é obrigatório",
              },
            ]}
          >
            <Upload accept="application/pdf" beforeUpload={() => false}>
              <Button status="info" htmlType="button">
                Anexar Nota Fiscal
              </Button>
            </Upload>
          </AntForm.Item>
        </AntForm>
      </Flex>
    </AntModal>
  );
};
