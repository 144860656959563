import {
  Typography,
  Flex,
  Input,
  Table,
  Card,
  Space,
  Form as AntForm,
} from "antd";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { FaRegCircleCheck } from "react-icons/fa6";

import { UserContext } from "../../../../../domain/context/user/UserContext";
import { useRequirement } from "../../../../hooks/useRequirement";
import {
  IRequirementService,
  RequirementSeriviceStatus,
} from "../../../../../domain/entities/Requirement";
import { Button } from "../../../../components/Button";

import * as RequirementComponent from "../../../../components/Requirement";

const { Text } = Typography;

export const List: React.FC = () => {
  const userContext = useContext(UserContext);

  const [search, setSearch] = useState("");
  const [selectedRequirementService, setSelectedRequirementService] =
    useState<string>();

  const [modalOpen, setModalOpen] = useState(false);
  const [form] = AntForm.useForm();

  const {
    loading,
    requirementServiceList,
    actions: { handleGetAllRequirements, handleConfirmRequirementService },
  } = useRequirement();

  const handleConfirm = useCallback(
    async (values: any) => {
      if (selectedRequirementService) {
        await handleConfirmRequirementService(
          selectedRequirementService,
          values
        );
        handleGetAllRequirements();
      }
    },
    [
      selectedRequirementService,
      handleConfirmRequirementService,
      handleGetAllRequirements,
    ]
  );

  const handleConclude = useCallback((id: string) => {
    setModalOpen(true);
    setSelectedRequirementService(id);
  }, []);

  const handleClose = useCallback(() => {
    setModalOpen(false);
    setSelectedRequirementService(undefined);
  }, []);

  const columns = useMemo(
    () => [
      {
        title: "Serviço",
        dataIndex: "service",
        key: "service",
        ellipsis: {
          showTitle: false,
        },
        render: (_: any, record: IRequirementService) => (
          <Text>{record.service.service_name}</Text>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        ellipsis: {
          showTitle: false,
        },
        render: (_: any, record: IRequirementService) => (
          <Text>{RequirementSeriviceStatus[record.status]}</Text>
        ),
      },
      {
        title: "Quantidade",
        dataIndex: "quantity",
        key: "quantity",
        ellipsis: {
          showTitle: false,
        },
        render: (_: any, record: IRequirementService) => (
          <Text>{record.quantity}</Text>
        ),
      },
      {
        title: "Ações",
        dataIndex: "actions",
        key: "actions",
        width: 100,
        render: (_: any, record: IRequirementService) => {
          return (
            <Space size="small">
              {userContext.user?.scopes?.includes(
                "requirement:requirement_services:conclude"
              ) &&
                record.status === "DISPATCHED" && (
                  <Button
                    className="p-2 flex items-center"
                    type="text"
                    data-testid="edit-service-provider"
                    onClick={() => handleConclude(record.id)}
                  >
                    <FaRegCircleCheck size={18} />
                  </Button>
                )}
            </Space>
          );
        },
      },
    ],
    [userContext.user?.scopes, handleConclude]
  );

  useEffect(() => {
    handleGetAllRequirements();
  }, [handleGetAllRequirements]);

  const filteredRequirement = useMemo(
    () =>
      requirementServiceList?.filter((requirement) =>
        requirement.service.toString().includes(search)
      ),
    [requirementServiceList, search]
  );

  return (
    <>
      <Card className="w-full">
        <Flex vertical gap="middle">
          <Flex justify="space-between" align="center">
            <AntForm>
              <Text>Pesquisar</Text>
              <Input
                placeholder="Pesquisar..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </AntForm>
          </Flex>
          <Table
            className="w-full border rounded"
            columns={columns}
            dataSource={filteredRequirement}
            pagination={{ pageSize: 8 }}
            loading={loading}
            rowKey={(row) => row.id}
          />
        </Flex>
      </Card>
      <RequirementComponent.ConfirmServiceModal
        form={form}
        open={modalOpen}
        onConfirm={handleConfirm}
        onClose={handleClose}
      />
    </>
  );
};
