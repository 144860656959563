import { Card, Col, Flex, Typography } from "antd";
import { ChartDataset, ChartOptions, ChartType } from "chart.js";
import { Chart as ReactChart } from "react-chartjs-2";
import React from "react";

const { Text } = Typography;

export const COLOR_SCHEMA = [
  "#A3C1DA",
  "#FFB07C",
  "#A8D5A2",
  "#EFA7A7",
  "#C4A4D6",
  "#C8A582",
  "#F7B7D2",
  "#B3B3B3",
  "#E3E697",
  "#A0DDEB",
];

type ChartProps = {
  title?: string;
  data: ChartDataset[];
  labels?: string[];
  type: ChartType;
  responsive?: {
    [key: string]: number;
  };
  options?: ChartOptions;
};

export const Chart: React.FC<ChartProps> = ({
  title,
  labels,
  data,
  type,
  responsive = {},
  options,
}) => {
  return (
    <Col {...responsive}>
      <Card>
        <Flex vertical gap={"large"}>
          <Text ellipsis>{title}</Text>
          <ReactChart
            type={type}
            className="print:max-w-full print:w-full print:!h-[260px]"
            options={{
              ...options,
              plugins: { legend: { display: type !== "bar" } },
            }}
            data={{
              labels,
              datasets: data.map((d, i) => ({
                ...d,
                backgroundColor:
                  type === "line" ? COLOR_SCHEMA[i] : COLOR_SCHEMA,
                borderColor: type === "line" ? COLOR_SCHEMA : "white",
              })),
            }}
          />
        </Flex>
      </Card>
    </Col>
  );
};
