import React from "react";
import {
  Modal,
  Form as AntForm,
  Input,
  DatePicker,
  Upload,
  Select,
  Typography,
  Space,
  FormInstance,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Button } from "./Button";
import { DatePickerProps } from "antd/lib";

const { Text } = Typography;

interface NewFactModalProps {
  isModalOpen: boolean;
  form: FormInstance;
  currentStatus: string;
  loading?: boolean;
  statusOptions: { value: string; label: string }[];
  handleDatePickerChange: DatePickerProps["onChange"];
  updateStatus: (value: string) => void;
  closeModal: () => void;
  handleSubmit: (values: any) => void;
}

export const NewFactModal = ({
  loading = false,
  isModalOpen,
  form,
  currentStatus,
  statusOptions,
  updateStatus,
  handleDatePickerChange,
  closeModal,
  handleSubmit,
}: NewFactModalProps) => {
  return (
    <Modal
      title="Informações Adicionais"
      open={isModalOpen}
      onCancel={closeModal}
      footer={[
        <Button key="back" status="danger" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          status="success"
          key="submit"
          onClick={form.submit}
          data-testid="send-button-modal"
          loading={loading}
        >
          Enviar
        </Button>,
      ]}
    >
      <AntForm layout="vertical" onFinish={handleSubmit} form={form}>
        <AntForm.Item
          name="justification"
          label="Justificativa"
          rules={[
            {
              required: true,
              message: "Por favor, adicione uma justificativa.",
            },
          ]}
        >
          <Input.TextArea
            className="h-32 resize-none"
            placeholder="Adicione um novo fato"
          />
        </AntForm.Item>
        <div className="flex gap-10">
          <AntForm.Item
            name="date"
            label="Data"
            rules={[
              { required: true, message: "Por favor, selecione uma data." },
            ]}
          >
            <DatePicker
              placeholder={"Selecione uma Data"}
              format="DD/MM/YYYY"
              onChange={handleDatePickerChange}
            />
          </AntForm.Item>

          <AntForm.Item name="file_url" label="Arquivo">
            <Upload beforeUpload={() => false}>
              <Button status="info" icon={<UploadOutlined />}>
                Selecionar Arquivo
              </Button>
            </Upload>
          </AntForm.Item>
        </div>
        <div className="flex flex-col gap-small">
          <Text>Status</Text>
          <div className="flex flex-col justify-start align-start">
            <Space wrap>
              <Select
                className="w-60"
                data-testid="status-select"
                value={currentStatus}
                onChange={(value) => updateStatus(value)}
                options={statusOptions}
              />
            </Space>
          </div>
        </div>
      </AntForm>
    </Modal>
  );
};
