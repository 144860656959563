import { Card, Col, Flex, Row, Spin, Typography } from "antd";
import React, { useEffect } from "react";
import { useItem } from "../../../hooks/useItem";
import { Link, useParams } from "react-router-dom";
import { ItemType } from "../../../../domain/entities/Item";
import dayjs from "dayjs";

const { Text } = Typography;

export const Info: React.FC = () => {
  const {
    loading,
    item,
    actions: { handleGetItem },
  } = useItem();

  const params = useParams();

  useEffect(() => {
    if (params.id) {
      handleGetItem(params.id);
    }
  }, [params, handleGetItem]);

  return (
    <Flex vertical gap={"middle"} data-testid="item-info">
      <Card
        title={
          item?.type.toUpperCase() === ItemType.LICITATION
            ? "Licitação"
            : "Compra Direta"
        }
      >
        <Flex vertical gap="middle">
          <Spin spinning={loading}>
            <Card title="Informações" bordered={false} loading={loading}>
              <Flex vertical gap={"small"}>
                {item?.type.toUpperCase() === ItemType.LICITATION && (
                  <>
                    <Text>Pregão Eletrônico: {item?.order_id}</Text>
                    <Text>Modalidade: {item?.licitation?.modality}</Text>
                    <Text>
                      Validade Inicial:{" "}
                      {dayjs(item?.licitation?.start_validity).format(
                        "DD/MM/YYYY"
                      )}
                    </Text>
                    <Text>
                      Documento:{" "}
                      <Link target="__blank" to={item?.doc_url as string}>
                        {item?.doc_url}
                      </Link>
                    </Text>
                    <Text>
                      Foi Aditivado:{" "}
                      {item.licitation?.is_aditive ? "Sim" : "Não"}
                    </Text>
                    {item.licitation?.is_aditive && (
                      <>
                        <Text>
                          Data do Aditivo:{" "}
                          {dayjs(item.licitation?.aditive_date).format(
                            "DD/MM/YYYY"
                          )}
                        </Text>
                        <Text>
                          Nova validade:{" "}
                          {dayjs(item.licitation?.new_validity).format(
                            "DD/MM/YYYY"
                          )}
                        </Text>
                        <Text>
                          Documento do Aditivo:{" "}
                          <Link
                            target="__blank"
                            to={item?.licitation.doc_aditive_url as string}
                          >
                            {item?.licitation.doc_aditive_url}
                          </Link>
                        </Text>
                        <Text>
                          Razão do Aditivo: {item.licitation?.reason_aditive}
                        </Text>
                      </>
                    )}
                  </>
                )}
                {item?.type === ItemType.DIRECT_BUY && (
                  <>
                    <Row>
                      <Col xs={24} md={12}>
                        Documento:{" "}
                        <Link target="__blank" to={item?.doc_url as string}>
                          <Text className="font-semibold">
                            Baixar o documento
                          </Text>
                        </Link>
                      </Col>
                    </Row>
                  </>
                )}
              </Flex>
            </Card>
          </Spin>

          <Spin spinning={loading}>
            <Card title="Serviços" bordered={false}>
              <Flex vertical gap="middle" align="center">
                {item?.items_services.map((item_service) => (
                  <Card key={item_service.id} className="w-full">
                    <Flex vertical gap={"small"}>
                      <Text>
                        Prestador de Serviço:{" "}
                        {item_service.service_provider.user.full_name}
                      </Text>
                      <Text>Preço Unitário: {item_service.unit_price}</Text>
                      <Text>Quantidade: {item_service.quantity}</Text>
                      <Text>Serviço: {item_service.service.service_name}</Text>
                    </Flex>
                  </Card>
                ))}
              </Flex>
            </Card>
          </Spin>
        </Flex>
      </Card>
    </Flex>
  );
};
