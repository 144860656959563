import React, { useCallback, useEffect, useState } from "react";

import {
  Form as AntForm,
  Input,
  Row,
  Col,
  Flex,
  Card,
  Select,
  Typography,
  InputNumber,
  Upload,
} from "antd";
import { DatePicker } from "antd";
import { Button } from "../../../components/Button";
import { useConfig } from "../../../hooks/useConfig";
import { useServiceProvider } from "../../../hooks/useServiceProvider";
import { FaFileAlt, FaTrash } from "react-icons/fa";
import { useItem } from "../../../hooks/useItem";
import { ItemType } from "../../../../domain/entities/Item";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { IConfig } from "../../../../domain/entities/Config";
import { flattenObj } from "../../../utils/object";
import dayjs from "dayjs";
import { ROUTES } from "../../../Router";

const { Text } = Typography;

export const Form: React.FC = () => {
  const [form] = AntForm.useForm();

  const [searchParams] = useSearchParams();
  const params = useParams();

  const [isEditing, setIsEditing] = useState(false);
  const [type, setType] = useState<keyof typeof ItemType>(
    searchParams.get("type")?.toLocaleUpperCase() === ItemType.LICITATION
      ? ItemType.LICITATION
      : ItemType.DIRECT_BUY
  );

  const navigate = useNavigate();

  const {
    loading,
    item,
    actions: { handleSubmitItem, handleGetItem },
  } = useItem();

  const {
    serviceProviderList,
    actions: { handleGetAllServiceProvider },
  } = useServiceProvider();

  const config = useConfig();

  useEffect(() => {
    if (!isEditing) {
      handleGetAllServiceProvider();
    }
  }, [handleGetAllServiceProvider, isEditing]);

  useEffect(() => {
    if (params.id) {
      handleGetItem(params.id);
      setIsEditing(true);
    }
  }, [params.id, handleGetItem]);

  useEffect(() => {
    if (item && item.type.toUpperCase() === ItemType.LICITATION) {
      const fields = flattenObj(item);

      fields["licitation.start_validity"] = dayjs(
        fields["licitation.start_validity"]
      );
      fields["licitation.new_validity"] = dayjs(
        fields["licitation.new_validity"]
      );
      fields["licitation.aditive_date"] = dayjs(
        fields["licitation.aditive_date"]
      );

      form.setFieldsValue(fields);

      setType(ItemType.LICITATION);
    }
  }, [item, form]);

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        await handleSubmitItem(values);
        navigate(ROUTES.DASHBOARD.ITEMS.ROOT);
      } catch (error: any) {
        new Error(error);
      }
    },
    [handleSubmitItem, navigate]
  );

  return (
    <AntForm
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      className="w-full"
    >
      <Flex vertical gap={"middle"}>
        <Card
          loading={loading}
          title={type === ItemType.LICITATION ? "Licitação" : "Compra Direta"}
        >
          <AntForm.Item name={"id"} noStyle hidden>
            <Input />
          </AntForm.Item>
          <AntForm.Item
            initialValue={
              searchParams.get("type") === "licitation"
                ? ItemType.LICITATION
                : ItemType.DIRECT_BUY
            }
            name={"type"}
            noStyle
            hidden
          >
            <Input />
          </AntForm.Item>
          <Flex vertical gap="middle">
            {type === ItemType.LICITATION ? (
              <LiciationForm config={config} isEditing={isEditing} />
            ) : (
              <DirectBuyForm />
            )}

            <Card title="Serviços">
              <AntForm.List
                name="items_services"
                initialValue={form.getFieldValue("items_services") ?? [{}]}
              >
                {(fields, { add, remove }) => (
                  <Flex vertical gap="middle" align="center">
                    {fields.map((field, index) => {
                      const maxQtd = item?.items_services[index].quantity
                        ? Math.floor(item?.items_services[index].quantity * 1.3)
                        : undefined;
                      return (
                        <Card key={field.key} className="w-full">
                          <Row gutter={{ xs: 8, md: 16 }}>
                            <Col xs={24} sm={24} md={12} lg={6}>
                              <AntForm.Item
                                name={[field.name, "service_provider_id"]}
                                label="Prestador de Serviço"
                                rules={[
                                  {
                                    required: !isEditing,
                                    message:
                                      "Prestado de serviço é obrigatório",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Selecione"
                                  data-testid="service-provider-select"
                                  disabled={isEditing}
                                  options={[
                                    ...(serviceProviderList?.map(
                                      (servideProvider) => ({
                                        label: servideProvider.user.full_name,
                                        value: servideProvider.id,
                                      })
                                    ) ?? []),
                                  ]}
                                />
                              </AntForm.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={7}>
                              <AntForm.Item
                                name={[field.name, "service_id"]}
                                label="Serviço"
                                className="m-0"
                                rules={[
                                  {
                                    required: !isEditing,
                                    message: "Serviço é obrigatório",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Selecione"
                                  data-testid="service-select"
                                  disabled={isEditing}
                                  options={[
                                    ...config.services.map((service) => ({
                                      label: service.service_name,
                                      value: service.id,
                                    })),
                                  ]}
                                />
                              </AntForm.Item>
                            </Col>
                          </Row>

                          <Row gutter={{ xs: 8, md: 16 }}>
                            <Col xs={24} sm={24} md={12} lg={8}>
                              <AntForm.Item
                                label="Preço Unitário"
                                name={[field.name, "unit_price"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Preço unitário é obrigatório",
                                  },
                                ]}
                              >
                                <InputNumber
                                  prefix="R$"
                                  disabled={isEditing}
                                  className="w-full"
                                  decimalSeparator=","
                                  placeholder="Preço Unitário"
                                />
                              </AntForm.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8}>
                              <AntForm.Item
                                name={[field.name, "quantity"]}
                                label="Quantidade"
                                rules={[
                                  {
                                    required: true,
                                    message: "Quantidade é obrigatório",
                                  },
                                ]}
                              >
                                <InputNumber
                                  placeholder="Quantidade"
                                  type="number"
                                  max={maxQtd}
                                  className="!w-32"
                                />
                              </AntForm.Item>
                            </Col>
                          </Row>

                          {!isEditing && (
                            <Button
                              danger
                              data-testid="remove-item-service"
                              className="absolute top-2 right-2"
                              onClick={() => remove(index)}
                            >
                              <FaTrash />
                            </Button>
                          )}
                        </Card>
                      );
                    })}

                    {!isEditing && (
                      <Button
                        type="dashed"
                        data-testid="add-item-service"
                        onClick={() => add()}
                        className="!w-10 !h-10 rounded-full text-xl flex items-center justify-center"
                      >
                        +
                      </Button>
                    )}
                  </Flex>
                )}
              </AntForm.List>
            </Card>
            <Flex justify="flex-end" gap={"small"}>
              {isEditing && (
                <AntForm.Item
                  name={"licitation.doc_aditive_url"}
                  rules={[
                    {
                      required: true,
                      message: "Anexar o documento do aditivo é obrigatório",
                    },
                  ]}
                >
                  <Upload accept="application/pdf" beforeUpload={() => false}>
                    <Button htmlType="button" status="info">
                      Anexar Documento do Aditivo
                    </Button>
                  </Upload>
                </AntForm.Item>
              )}

              {isEditing ? (
                <Link to={item?.doc_url ?? ""} target="_blank">
                  <Button
                    status="info"
                    htmlType="button"
                    icon={<FaFileAlt />}
                    className="flex items-center"
                  >
                    Documento
                  </Button>
                </Link>
              ) : (
                <AntForm.Item
                  name={"doc_url"}
                  rules={[
                    {
                      required: true,
                      message: "Anexar o documento é obrigatório",
                    },
                  ]}
                >
                  <Upload accept="application/pdf" beforeUpload={() => false}>
                    <Button status="info" htmlType="button">
                      Anexar Documento
                    </Button>
                  </Upload>
                </AntForm.Item>
              )}

              <Button
                status="danger"
                htmlType="button"
                onClick={() => navigate(-1)}
              >
                Cancelar
              </Button>
              <AntForm.Item noStyle>
                <Button htmlType="submit" status="success">
                  Enviar
                </Button>
              </AntForm.Item>
            </Flex>
          </Flex>
        </Card>
      </Flex>
    </AntForm>
  );
};

type FormProps = {
  config: IConfig;
  isEditing: boolean;
};

const LiciationForm: React.FC<FormProps> = ({ config, isEditing }) => {
  return (
    <>
      <Row align="middle" gutter={{ xs: 8, md: 16 }}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AntForm.Item
            name={"order_id"}
            label="Nº Pregão Eletronico"
            className="m-0"
            rules={[
              { required: true, message: "Nº Pregão Eletronico é obrigatório" },
            ]}
          >
            <Input disabled={isEditing} placeholder="Nº Pregão Eletronico" />
          </AntForm.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <AntForm.Item
            name={"licitation.modality"}
            label="Modalidade"
            required
            className="m-0"
            rules={[{ required: true, message: "Modalidade é obrigatório" }]}
          >
            <Select
              disabled={isEditing}
              placeholder="Selecione"
              options={[
                ...config.modalities.map((modality) => ({
                  label: modality,
                  value: modality,
                })),
              ]}
            />
          </AntForm.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={8}>
          <AntForm.Item
            name={"licitation.start_validity"}
            label="Validade"
            className="m-0"
            rules={[{ required: true, message: "Validade é obrigatório" }]}
          >
            <DatePicker
              disabled={isEditing}
              className="w-full"
              format={"DD/MM/YYYY"}
            />
          </AntForm.Item>
        </Col>
      </Row>
      {isEditing && (
        <Card title="Aditivo">
          <Flex vertical gap={"small"}>
            <Row align="middle" gutter={{ xs: 8, md: 16 }}>
              <Col xs={24} sm={24} md={6} lg={8}>
                <AntForm.Item
                  name={"licitation.aditive_date"}
                  label="Data do Aditivo"
                  rules={[
                    {
                      required: true,
                      message: "Data do aditivo é obrigatório",
                    },
                  ]}
                  className="m-0"
                >
                  <DatePicker className="w-full" format={"DD/MM/YYYY"} />
                </AntForm.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <AntForm.Item
                  name={"licitation.new_validity"}
                  label="Nova Validade"
                  className="m-0"
                >
                  <DatePicker className="w-full" format={"DD/MM/YYYY"} />
                </AntForm.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={6} lg={24}>
                <AntForm.Item
                  name={"licitation.reason_aditive"}
                  label="Razão do Aditivo"
                  className="m-0"
                  rules={[
                    {
                      required: true,
                      message: "Razão do aditivo é obrigatório",
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Razão do Aditivo"
                    className="!resize-none !h-20"
                  />
                </AntForm.Item>
              </Col>
            </Row>
          </Flex>
        </Card>
      )}
    </>
  );
};

const DirectBuyForm: React.FC = () => {
  return (
    <Row align="middle" gutter={{ xs: 8, md: 16 }}>
      <Col xs={24} sm={24} md={5}>
        <Text className="text-lg">Compra Direta</Text>
      </Col>
      <Col xs={24} sm={24} md={12}>
        <AntForm.Item
          name={"order_id"}
          label="Ordem de Compra"
          rules={[{ required: true, message: "Ordem de compra é obrigatório" }]}
          className="m-0"
        >
          <Input placeholder="Ordem de Compra" />
        </AntForm.Item>
      </Col>
    </Row>
  );
};
