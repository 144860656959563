import {
  Modal as AntModal,
  Flex,
  Typography,
  Form as AntForm,
  Input,
  Button,
  FormInstance,
} from "antd";
import React from "react";

const { Text } = Typography;

type RequirementModalProps = {
  open: boolean;
  form: FormInstance;
  onClose: () => void;
  onConfirm: (values: any) => void;
};

export const RejectModal: React.FC<RequirementModalProps> = ({
  open,
  form,
  onClose,
  onConfirm,
}) => {
  return (
    <AntModal
      title="Indeferir Requerimento"
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          onClick={form.submit}
          data-testid="send-button-modal"
          danger
          loading={false}
        >
          Enviar
        </Button>,
      ]}
    >
      <Flex vertical gap={"middle"}>
        <Text>
          Informe uma justificativa para <Text strong>INDEFERIR</Text> o
          requerimento
        </Text>
        <AntForm layout="vertical" onFinish={onConfirm} form={form}>
          <AntForm.Item
            name="justification"
            label="Justificativa"
            rules={[
              {
                required: true,
                message: "Por favor, adicione uma justificativa.",
              },
            ]}
          >
            <Input.TextArea
              className="!h-32 !resize-none"
              placeholder="Motivo do indeferimento"
            />
          </AntForm.Item>
        </AntForm>
      </Flex>
    </AntModal>
  );
};
