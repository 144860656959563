import { Typography, Flex, Input, Table, Card, Space, TableProps } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Router";
import { Button } from "../../../components/Button";
import { useServiceProvider } from "../../../hooks/useServiceProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import { IServiceProvider } from "../../../../domain/entities/ServiceProvider";

import { MdModeEdit } from "react-icons/md";
import { UserContext } from "../../../../domain/context/user/UserContext";

const { Text } = Typography;

export const List: React.FC = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const [search, setSearch] = useState("");

  const {
    serviceProviderList,
    loading,
    actions: { handleGetAllServiceProvider },
  } = useServiceProvider();

  const columns: TableProps<IServiceProvider>["columns"] = useMemo(
    () => [
      {
        title: "Nome Fantasia",
        dataIndex: "full_name",
        key: "full_name",
        ellipsis: {
          showTitle: false,
        },
        render: (_: any, record: IServiceProvider) => (
          <Text>{record.user.full_name}</Text>
        ),
      },
      {
        title: "CNPJ",
        dataIndex: "id_number",
        key: "id_number",
        render: (_: any, record: IServiceProvider) => (
          <Text>{record.user.id_number}</Text>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        responsive: ["lg"],
        render: (_: any, record: IServiceProvider) => (
          <Text>{record.user.email}</Text>
        ),
      },
      {
        title: "Ações",
        dataIndex: "actions",
        key: "actions",
        width: 120,
        render: (_: any, record: IServiceProvider) => {
          return (
            <Space size="small">
              {userContext.user?.scopes?.includes(
                "service_provider:update"
              ) && (
                <Button
                  className="p-2"
                  type="text"
                  data-testid="edit-service-provider"
                  onClick={() =>
                    navigate(
                      ROUTES.DASHBOARD.SERVICE_PROVIDER.EDIT.replace(
                        ":id",
                        record.id
                      )
                    )
                  }
                >
                  <MdModeEdit size={18} />
                </Button>
              )}
            </Space>
          );
        },
      },
    ],
    [navigate, userContext.user?.scopes]
  );

  const handleClickAdd = () => {
    navigate(ROUTES.DASHBOARD.SERVICE_PROVIDER.ADD);
  };

  useEffect(() => {
    handleGetAllServiceProvider();
  }, [handleGetAllServiceProvider]);

  const filteredServiceProviderList = useMemo(
    () =>
      serviceProviderList?.filter((serviceProvider) =>
        serviceProvider.user.full_name
          .toLowerCase()
          .includes(search.toLowerCase())
      ),
    [serviceProviderList, search]
  );

  return (
    <Card className="w-full">
      <Flex vertical gap="middle">
        <Flex justify="space-between" align="centers">
          <Flex vertical gap={5} justify="flex-start" align="flex-start">
            <Text>Pesquisar</Text>
            <Input
              placeholder="Pesquisar..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Flex>
          {userContext.user?.scopes?.includes("service_provider:create") && (
            <Flex gap="small" wrap="wrap" justify="center" align="center">
              <Button onClick={handleClickAdd} status="info">
                Criar Novo
              </Button>
            </Flex>
          )}
        </Flex>
        <Table
          className="w-full border rounded"
          columns={columns}
          dataSource={filteredServiceProviderList}
          pagination={{ pageSize: 8 }}
          loading={loading}
          rowKey={(row) => row.id}
        />
      </Flex>
    </Card>
  );
};
