import React from "react";
import ReactDOM from "react-dom/client";
import { initializePresentationLayer } from "./presentation/PresentationLayer";
import { initializeDataLayer } from "./data/DataLayer";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

initializeDataLayer();

const App = initializePresentationLayer();

root.render(
  <React.StrictMode>
    <div />
  </React.StrictMode>
);
