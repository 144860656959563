import { useContext, useEffect, useMemo, useReducer } from "react";
import { ITicket, TicketStatus } from "../../../../domain/entities/Ticket";
import {
  Flex,
  Input,
  Space,
  Table,
  Typography,
  Card,
  Select,
  TableProps,
  Row,
} from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Router";
import { useTicket } from "../../../hooks/useTicket";
import { TbExternalLink } from "react-icons/tb";
import { Button } from "../../../components/Button";
import { FaEye, FaPencilAlt } from "react-icons/fa";
import { UserContext } from "../../../../domain/context/user/UserContext";
const { Text } = Typography;

export const TicketList: React.FC = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const {
    loading,
    ticketList,
    actions: { handleGetAllTickets },
  } = useTicket();

  const filterReducer = (filter: any, info: any) => ({
    ...filter,
    ...info,
  });

  const [filter, changeFilter] = useReducer(filterReducer, {
    searchIdentifier: "",
    occurred: "",
    status: "",
  });

  const columns: TableProps<ITicket>["columns"] = useMemo(
    () => [
      {
        title: "Chamados",
        dataIndex: "report_type",
        key: "report_type",
        ellipsis: {
          showTitle: false,
        },
        width: 180,
        render: (_: any, record: ITicket) => (
          <Text>{record.protocol_number}</Text>
        ),
      },
      {
        title: "Ocorrido",
        dataIndex: "sequencial_id",
        key: "sequencial_id",
        ellipsis: {
          showTitle: false,
        },
        responsive: ["lg"],
        render: (_: any, record: ITicket) => <Text>{record.description}</Text>,
      },
      {
        title: "Denuncia",
        dataIndex: "protocol_number",
        key: "protocol_number",
        ellipsis: {
          showTitle: false,
        },
        responsive: ["lg"],
        render: (_: any, record: ITicket) => (
          <Flex className="flex-row items-center">
            {record.denouncement_id ? (
              <a
                href={ROUTES.DASHBOARD.DENOUNCEMENT.INFO.replace(
                  ":id",
                  record.denouncement_id
                )}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-1"
              >
                <TbExternalLink size={16} className="ml-1" />
                <Text>Link</Text>
              </a>
            ) : (
              <Text>Nenhuma denúncia vinculada</Text>
            )}
          </Flex>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        ellipsis: {
          showTitle: false,
        },
        render: (_: any, record: ITicket) => (
          <Text>
            {TicketStatus[record.status as keyof typeof TicketStatus]}
          </Text>
        ),
      },
      {
        title: "Ações",
        dataIndex: "actions",
        key: "actions",
        width: 150,
        render: (_: any, record: ITicket) => (
          <Space size="small">
            {record.status !== "DENIED" && (
              <>
                {userContext.user?.scopes?.includes("ticket:update") && (
                  <Button
                    className="p-2"
                    type="text"
                    data-testid="edit-ticket"
                    onClick={() =>
                      navigate(
                        ROUTES.DASHBOARD.TICKET.EDIT.replace(":id", record.id)
                      )
                    }
                  >
                    <FaPencilAlt size={18} />
                  </Button>
                )}
              </>
            )}
            {userContext.user?.scopes?.includes("ticket:read") && (
              <Button
                className="p-2"
                type="text"
                data-testid="view-ticket"
                onClick={() =>
                  navigate(
                    ROUTES.DASHBOARD.TICKET.INFO.replace(":id", record.id)
                  )
                }
              >
                <FaEye size={18} />
              </Button>
            )}
          </Space>
        ),
      },
    ],
    [navigate, userContext.user?.scopes]
  );

  const handleClickAdd = () => {
    navigate(ROUTES.DASHBOARD.TICKET.ADD);
  };

  const filterTicket = useMemo(() => {
    if (!ticketList) {
      return [];
    }
    return ticketList
      ?.filter((ticketFilter) =>
        ticketFilter.protocol_number
          .toLowerCase()
          .includes(filter.searchIdentifier.toLowerCase())
      )
      .filter((ticketFilter) =>
        filter.occurred !== ""
          ? ticketFilter.description
              .toLowerCase()
              .startsWith(filter.occurred.toLowerCase())
          : true
      )
      .filter((ticketFilter) =>
        filter.status !== "" ? ticketFilter.status === filter.status : true
      );
  }, [ticketList, filter]);

  useEffect(() => {
    handleGetAllTickets();
  }, [handleGetAllTickets]);

  return (
    <>
      <Card className="w-full">
        <Flex vertical gap="middle">
          <Row>
            <Flex
              gap={10}
              wrap="wrap"
              justify="space-between"
              align="centers"
              className="w-full"
            >
              <Flex gap="large">
                <Flex vertical gap={2} justify="flex-start" align="flex-start">
                  <Text>Pesquisar Chamado</Text>
                  <Input
                    placeholder="Digite o Chamado"
                    value={filter.searchIdentifier}
                    onChange={(e) =>
                      changeFilter({ searchIdentifier: e.target.value })
                    }
                    className="ml-2 w-40"
                  />
                </Flex>
                <Flex vertical gap={2} justify="flex-start" align="flex-start">
                  <Text>Pesquisar Ocorrido</Text>
                  <Input
                    placeholder="Digite o ocorrido"
                    value={filter.occurred}
                    onChange={(e) => changeFilter({ occurred: e.target.value })}
                    className="ml-2 w-40"
                  />
                </Flex>
                <Flex vertical gap={2} justify="flex-start" align="flex-start">
                  <Text>Status</Text>
                  <Space wrap>
                    <Select
                      defaultValue={filter.status}
                      className="w-40"
                      onChange={(value) => changeFilter({ status: value })}
                      options={[
                        { value: "", label: "Todos" },
                        { value: "OPEN", label: "Aberto" },
                        { value: "ON_GOING", label: "Em Andamento" },
                        { value: "CLOSED", label: "Fechado" },
                      ]}
                    />
                  </Space>
                </Flex>
              </Flex>
              <Flex gap="small" wrap="wrap" justify="flex-end" align="end">
                {userContext.user?.scopes?.includes("item:create") && (
                  <>
                    <Button
                      data-testid="create-ticket"
                      onClick={handleClickAdd}
                      status="info"
                    >
                      Criar Novo
                    </Button>
                  </>
                )}
              </Flex>
            </Flex>
          </Row>
          <Table
            className="w-full border rounded"
            columns={columns}
            dataSource={filterTicket}
            pagination={{ pageSize: 8 }}
            loading={loading}
            rowKey={(row) => row.id}
          />
        </Flex>
      </Card>
    </>
  );
};
