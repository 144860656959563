import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Login } from "./pages/Login";
import { PublicRoute } from "./components/PublicRoute";
import { PrivateRoute } from "./components/PrivateRoute";
import {
  Campaign,
  Denouncement,
  Ticket,
  FoodBank,
  Protector,
  Requirement,
  ServiceProvider,
  Items,
  Microchip,
  Config,
} from "./pages/Dashboard";
import { ItemsRoot } from "./pages/Dashboard/Items";
import { ServiceProviderRoot } from "./pages/Dashboard/ServiceProvider";
import { DenouncementRoot } from "./pages/Dashboard/Denouncement";
import { TicketRoot } from "./pages/Dashboard/Ticket";
import { ProtectorRoot } from "./pages/Dashboard/Protector";
import { RequirementRoot } from "./pages/Dashboard/Requirement";
import { CampaignRoot } from "./pages/Dashboard/Campaign";
import { AnimalRoot } from "./pages/Dashboard/Animals";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { VerifyTutor } from "./pages/VerifyTutor";
import { ResetPassword } from "./pages/ResetPassword";

export const ROUTES = {
  ROOT: "/",

  // PUBLIC ROUTES
  LOGIN: "/login",
  VERIFY_TUTOR: "/confirm-account",
  RESET_PASSWORD: "/reset-password",

  // PRIVATE ROUTES
  DASHBOARD: {
    ROOT: "/dashboard",
    SERVICE_PROVIDER: {
      ROOT: "/dashboard/service-provider",
      ADD: "/dashboard/service-provider/add",
      EDIT: "/dashboard/service-provider/edit/:id?",
    },
    ITEMS: {
      ROOT: "/dashboard/items",
      STOCK: "/dashboard/items/stock",
      ADD: "/dashboard/items/add",
      EDIT: "/dashboard/items/edit/:id?",
      INFO: "/dashboard/items/info/:id?",
    },
    DENOUNCEMENT: {
      ROOT: "/dashboard/denouncement",
      INFO: "/dashboard/denouncement/info/:id?",
    },
    TICKET: {
      ROOT: "/dashboard/ticket",
      ADD: "/dashboard/ticket/add",
      EDIT: "/dashboard/ticket/edit/:id?",
      INFO: "/dashboard/ticket/info/:id?",
    },
    PROTECTOR: {
      ROOT: "/dashboard/protector",
      INFO: "/dashboard/protector/info/:id?",
      ACTIVE: {
        ROOT: "/dashboard/protector/actives",
        INFO: "/dashboard/protector/actives/:id?",
      },
    },
    CAMPAIGN: {
      ROOT: "/dashboard/campaign",
      INFO: "/dashboard/campaign/info/:id?",
      ADD: "/dashboard/campaign/add",
      QUEUE: "/dashboard/campaign/queue/:id?",
      SCHEDULE: "/dashboard/campaign/schedule/:id?",
    },

    REQUIREMENT: {
      ROOT: "/dashboard/requirement",
      INFO: "/dashboard/requirement/info/:id?",
      ADD: "/dashboard/requirement/add",
      EDIT: "/dashboard/requirement/edit/:id?",
      SERVICE_PROVIDER: {
        ROOT: "/dashboard/requirement/service-provider",
      },
    },
    ANIMALS: {
      ROOT: "/dashboard/animals",
    },
    SERVICE: {
      ROOT: "/dashboard/service",
      ADD: "/dashboard/service/add",
      EDIT: "/dashboard/service/edit/:id?",
    },
    PROGRAM: {
      ROOT: "/dashboard/program",
      FOOD_BANK: "/dashboard/program/food-bank",
    },
  },
};

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.VERIFY_TUTOR} element={<VerifyTutor />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />

        <Route path={ROUTES.ROOT} element={<PublicRoute />}>
          <Route path={ROUTES.ROOT} element={<Login />} />
        </Route>

        <Route path={ROUTES.ROOT} element={<PrivateRoute />}>
          <Route path={ROUTES.DASHBOARD.ROOT} element={<Dashboard />} />
          <Route
            path={ROUTES.DASHBOARD.SERVICE.ROOT}
            element={<Config.Service.ServiceRoot />}
          >
            <Route index element={<Config.Service.List />} />
            <Route
              path={ROUTES.DASHBOARD.SERVICE.ADD}
              element={<Config.Service.Form />}
            />
            <Route
              path={ROUTES.DASHBOARD.SERVICE.EDIT}
              element={<Config.Service.Form />}
            />
          </Route>
          <Route
            path={ROUTES.DASHBOARD.SERVICE_PROVIDER.ROOT}
            element={<ServiceProviderRoot />}
          >
            <Route index element={<ServiceProvider.List />} />
            <Route
              path={ROUTES.DASHBOARD.SERVICE_PROVIDER.ADD}
              element={<ServiceProvider.Form />}
            />
            <Route
              path={ROUTES.DASHBOARD.SERVICE_PROVIDER.EDIT}
              element={<ServiceProvider.Form />}
            />
          </Route>
          <Route path={ROUTES.DASHBOARD.ITEMS.ROOT} element={<ItemsRoot />}>
            <Route index element={<Items.Licitation />} />
            <Route path={ROUTES.DASHBOARD.ITEMS.ADD} element={<Items.Form />} />
            <Route
              path={ROUTES.DASHBOARD.ITEMS.EDIT}
              element={<Items.Form />}
            />
            <Route
              path={ROUTES.DASHBOARD.ITEMS.INFO}
              element={<Items.Info />}
            />
            <Route
              path={ROUTES.DASHBOARD.ITEMS.STOCK}
              element={<Items.Stock />}
            />
          </Route>

          <Route
            path={ROUTES.DASHBOARD.DENOUNCEMENT.ROOT}
            element={<DenouncementRoot />}
          >
            <Route index element={<Denouncement.List />} />
            <Route
              path={ROUTES.DASHBOARD.DENOUNCEMENT.INFO}
              element={<Denouncement.Info />}
            />
          </Route>

          <Route path={ROUTES.DASHBOARD.TICKET.ROOT} element={<TicketRoot />}>
            <Route index element={<Ticket.TicketList />} />
            <Route
              path={ROUTES.DASHBOARD.TICKET.INFO}
              element={<Ticket.TicketInfo />}
            />
            <Route
              path={ROUTES.DASHBOARD.TICKET.ADD}
              element={<Ticket.TicketAdd />}
            />
            <Route
              path={ROUTES.DASHBOARD.TICKET.EDIT}
              element={<Ticket.TicketAdd />}
            />
          </Route>

          <Route
            path={ROUTES.DASHBOARD.PROTECTOR.ROOT}
            element={<ProtectorRoot />}
          >
            <Route index element={<Protector.ProtectorList />} />
            <Route
              path={ROUTES.DASHBOARD.PROTECTOR.INFO}
              element={<Protector.ProtectorInfo />}
            />
            <Route path={ROUTES.DASHBOARD.PROTECTOR.ACTIVE.ROOT}>
              <Route index element={<Protector.Active.List />} />
              <Route
                path={ROUTES.DASHBOARD.PROTECTOR.ACTIVE.INFO}
                element={<Protector.Active.Info />}
              />
            </Route>
          </Route>

          <Route
            path={ROUTES.DASHBOARD.CAMPAIGN.ROOT}
            element={<CampaignRoot />}
          >
            <Route index element={<Campaign.List />} />
            <Route
              path={ROUTES.DASHBOARD.CAMPAIGN.INFO}
              element={<Campaign.Form />}
            />
            <Route
              path={ROUTES.DASHBOARD.CAMPAIGN.ADD}
              element={<Campaign.Form />}
            />
            <Route
              path={ROUTES.DASHBOARD.CAMPAIGN.QUEUE}
              element={<Campaign.Queue />}
            />
            <Route
              path={ROUTES.DASHBOARD.CAMPAIGN.SCHEDULE}
              element={<Campaign.Queue />}
            />
          </Route>

          <Route path={ROUTES.DASHBOARD.ANIMALS.ROOT} element={<AnimalRoot />}>
            <Route index element={<Microchip.List />} />
          </Route>
          <Route
            path={ROUTES.DASHBOARD.REQUIREMENT.ROOT}
            element={<RequirementRoot />}
          >
            <Route index element={<Requirement.List />} />
            <Route
              path={ROUTES.DASHBOARD.REQUIREMENT.INFO}
              element={<Requirement.Info />}
            />
            <Route
              path={ROUTES.DASHBOARD.REQUIREMENT.EDIT}
              element={<Requirement.Form />}
            />
            <Route
              path={ROUTES.DASHBOARD.REQUIREMENT.ADD}
              element={<Requirement.Form />}
            />
            <Route path={ROUTES.DASHBOARD.REQUIREMENT.SERVICE_PROVIDER.ROOT}>
              <Route index element={<Requirement.ServiceProvider.List />} />
            </Route>
          </Route>
          <Route path={ROUTES.DASHBOARD.PROGRAM.ROOT}>
            <Route
              path={ROUTES.DASHBOARD.PROGRAM.FOOD_BANK}
              element={<FoodBank />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
