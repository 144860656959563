import { useContext, useEffect, useMemo, useState } from "react";
import {
  Flex,
  Input,
  Space,
  Table,
  Typography,
  Card,
  Image,
  Modal,
  Form as AntForm,
  TableProps,
  Tooltip,
  Select,
  Checkbox,
} from "antd";
import { Button } from "../../../components/Button";
import { UserContext } from "../../../../domain/context/user/UserContext";
import {
  IPet,
  IPetFilters,
  PetGender,
  PetSpecies,
} from "../../../../domain/entities/Pet";
import { usePet } from "../../../hooks/usePet";
import { FaMicrochip } from "react-icons/fa6";
const { Text } = Typography;

export const List: React.FC = () => {
  const userContext = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [microchipFilter, setMicrochipFilter] = useState<string[]>([
    "Com",
    "Sem",
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [filters, setFilters] = useState<IPetFilters>({});
  const [form] = AntForm.useForm();

  const {
    loading,
    petList,
    actions: { handleGetAllPets, handlePatchMicrochip },
  } = usePet();

  const columns: TableProps<IPet>["columns"] = useMemo(
    () => [
      {
        title: "Foto",
        dataIndex: "image_url",
        key: "image_url",
        ellipsis: {
          showTitle: false,
        },
        width: 180,
        render: (_: any, record: IPet) => (
          <Image
            src={record.image_url}
            className="!w-20 !h-20 !object-cover rounded-md"
          />
        ),
      },
      {
        title: "Nome do Pet",
        dataIndex: "name",
        key: "name",
        ellipsis: {
          showTitle: false,
        },
        width: 180,
        render: (_: any, record: IPet) => <Text>{record.name}</Text>,
      },
      {
        title: "Nome do Tutor",
        dataIndex: "full_name",
        key: "full_name",
        ellipsis: {
          showTitle: false,
        },
        responsive: ["md"],
        render: (_: any, record: IPet) => (
          <Text> {record.tutor?.user.full_name}</Text>
        ),
      },
      {
        title: "Genero",
        dataIndex: "gender",
        key: "gender",
        ellipsis: {
          showTitle: false,
        },
        responsive: ["lg"],
        render: (_: any, record: IPet) => (
          <Text>{PetGender[record.gender as keyof typeof PetGender]}</Text>
        ),
      },

      {
        title: "Raça",
        dataIndex: "race",
        key: "race",
        ellipsis: {
          showTitle: false,
        },
        responsive: ["xl"],
        render: (_: any, record: IPet) => <Text>{record.race}</Text>,
      },
      {
        title: "Ações",
        dataIndex: "actions",
        key: "actions",
        width: 80,
        render: (_: any, record: IPet) => {
          return (
            <Space size="small">
              {userContext.user?.scopes?.includes("pet:read:all") &&
                !record.microchip_number && (
                  <Tooltip title="Adicionar microchip">
                    <Button
                      className="p-2 flex items-center justify-center"
                      type="text"
                      data-testid="open-modal"
                      onClick={() => openModal(record.id as string)}
                    >
                      <FaMicrochip size={18} />
                    </Button>
                  </Tooltip>
                )}
            </Space>
          );
        },
      },
    ],
    [userContext]
  );

  const filterPet = useMemo(() => {
    if (!petList) {
      return [];
    }
    return petList.filter((pet) => {
      const matchesMicrochipFilter =
        (microchipFilter.includes("Com") && pet.microchip_number) ||
        (microchipFilter.includes("Sem") && !pet.microchip_number);

      const matchesSearchTerm =
        pet.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        pet.race?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        pet.tutor?.user.full_name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        (!microchipFilter.includes("Com") &&
          pet.microchip_number?.includes(searchTerm.toLowerCase()));

      return matchesMicrochipFilter && matchesSearchTerm;
    });
  }, [microchipFilter, petList, searchTerm]);

  const openModal = (id: string) => {
    setSelectedId(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const microchipCheckboxOptions = [
    { label: "Com", value: "Com" },
    { label: "Sem", value: "Sem" },
  ];

  const onChangeCheckbox = (checkedValues: string[]) => {
    if (checkedValues.length === 0) {
      setMicrochipFilter(["Com", "Sem"]);
      return;
    }

    if (!checkedValues.includes("Com") && checkedValues.includes("Sem")) {
      setFilters({ ...filters, microchip_number: "null" });
    } else {
      setFilters({ ...filters, microchip_number: "" });
    }

    setMicrochipFilter(checkedValues);
  };

  const handleSubmit = async (values: any) => {
    await handlePatchMicrochip(selectedId, values.microchip_number);
    form.resetFields();
    closeModal();
    await handleGetAllPets(filters);
  };

  useEffect(() => {
    handleGetAllPets(filters);
  }, [filters, handleGetAllPets]);

  return (
    <>
      <Card className="w-full">
        <Flex vertical gap="middle">
          <Flex justify="space-between" align="centers" gap={5}>
            <Flex gap="large" wrap>
              <Flex vertical gap={5} justify="flex-start" align="flex-start">
                <Text>Pesquisar</Text>
                <Input
                  placeholder="Digite o nome, raça, microchip ou tutor"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="ml-2"
                />
              </Flex>
              <Flex vertical gap={5} justify="flex-start" align="flex-start">
                <Text>Status</Text>
                <Space wrap>
                  <Select
                    showSearch
                    defaultValue=""
                    className="w-40"
                    onChange={(value) =>
                      setFilters({ ...filters, status: value })
                    }
                    options={[
                      { value: "", label: "Todos" },
                      { value: "ACTIVE", label: "Ativo" },
                      { value: "INACTIVE", label: "Inativo" },
                    ]}
                  />
                </Space>
              </Flex>
              <Flex vertical gap={5} justify="flex-start" align="flex-start">
                <Text>Gênero</Text>
                <Space wrap>
                  <Select
                    showSearch
                    defaultValue=""
                    className="w-40"
                    onChange={(value) =>
                      setFilters({ ...filters, gender: value })
                    }
                    options={[
                      { value: "", label: "Todos" },
                      { value: "MACHO", label: "Macho" },
                      { value: "FÊMEA", label: "Fêmea" },
                    ]}
                  />
                </Space>
              </Flex>
              <Flex vertical gap={5} justify="flex-start" align="flex-start">
                <Text>Castração</Text>
                <Space wrap>
                  <Select
                    showSearch
                    defaultValue={""}
                    className="w-40"
                    onChange={(value) =>
                      setFilters({ ...filters, is_castrated: value })
                    }
                    options={[
                      { value: "", label: "Todos" },
                      { value: true, label: "Castrado" },
                      { value: false, label: "Não castrado" },
                    ]}
                  />
                </Space>
              </Flex>
              <Flex vertical gap={5} justify="flex-start" align="flex-start">
                <Text>Espécie</Text>
                <Space wrap>
                  <Select
                    showSearch
                    defaultValue={""}
                    className="w-40"
                    onChange={(value) =>
                      setFilters({ ...filters, species: value })
                    }
                    options={[
                      { value: "", label: "Todos" },
                      ...PetSpecies.map((specie) => ({
                        value: specie,
                        label: specie,
                      })),
                    ]}
                  />
                </Space>
              </Flex>
              <Flex vertical gap={5} justify="flex-start" align="flex-start">
                <Text>Microchip</Text>
                <Space wrap>
                  <Checkbox.Group
                    options={microchipCheckboxOptions}
                    defaultValue={["Com", "Sem"]}
                    value={microchipFilter}
                    onChange={onChangeCheckbox}
                  />
                </Space>
              </Flex>
            </Flex>
          </Flex>
          <Table
            className="w-full border rounded"
            columns={columns}
            dataSource={filterPet}
            pagination={{ pageSize: 8 }}
            loading={loading}
            rowKey={(row) => row.id as string}
          />
        </Flex>
      </Card>
      <Modal
        title="Deseja vincular um Microchip ao Pet?"
        open={isModalOpen}
        onCancel={closeModal}
        footer={[
          <Button
            status="success"
            key="submit"
            data-testid="success-modal-button"
            onClick={form.submit}
            loading={false}
          >
            Sim
          </Button>,
          <Button
            data-testid="unsuccess-modal-button"
            key="back"
            status="danger"
            onClick={closeModal}
          >
            Não
          </Button>,
        ]}
      >
        <AntForm layout="vertical" onFinish={handleSubmit} form={form}>
          <AntForm.Item
            name="microchip_number"
            label="Microchip"
            rules={[
              {
                required: true,
                message: "Por favor, adicione o Microchip",
              },
            ]}
          >
            <Input placeholder="Adicione um Microchip" />
          </AntForm.Item>
        </AntForm>
      </Modal>
    </>
  );
};
