import {
  Card,
  Col,
  Flex,
  Row,
  Typography,
  Image,
  Form as AntForm,
  Timeline,
} from "antd";
import { Button } from "../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useTicket } from "../../../hooks/useTicket";
import { useEffect, useState, useContext } from "react";
import { TicketStatus } from "../../../../domain/entities/Ticket";
import { ROUTES } from "../../../Router";
import { UserContext } from "../../../../domain/context/user/UserContext";
import dayjs from "dayjs";
import { DatePickerProps } from "antd/lib";
import { NewFactModal } from "../../../components/NewFactModal";

const { Text } = Typography;

export const TicketInfo: React.FC = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = AntForm.useForm();
  const [formattedDate, setFormattedDate] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");

  const TIMELINE_COLOR: any = {
    OPEN: "green",
    CLOSED: "red",
    ON_GOING: "yellow",
    EXTERNAL_PROCESS: "blue",
  };

  const handleSubmit = async (values: any) => {
    const valuesJustification: any = {
      new_fact: values.justification,
      file_url: values.denouncement_file,
      date: formattedDate,
      status: currentStatus,
    };

    await handlePostTicketInfo(id as string, valuesJustification);
    form.resetFields();
    await handleGetTicket(id as string);

    closeModal();
  };

  const redirectToOpenRequirement = () => {
    navigate(`${ROUTES.DASHBOARD.REQUIREMENT.ADD}?ticket=${id}`);
  };

  const {
    ticket,
    actions: { handleGetTicket, handlePostTicketInfo },
  } = useTicket();

  const { id } = useParams();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const updateStatus = async (value: string) => {
    setCurrentStatus(value);
  };

  useEffect(() => {
    if (id) {
      handleGetTicket(id);
    }
  }, [id, handleGetTicket]);

  useEffect(() => {
    if (ticket) {
      setCurrentStatus(
        TicketStatus[ticket.status as keyof typeof TicketStatus]
      );
    }
  }, [ticket]);

  const handleDatePickerChange: DatePickerProps["onChange"] = (date) => {
    if (date) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setFormattedDate(formattedDate);
    }
  };

  return (
    <>
      <Flex
        vertical
        gap={"middle"}
        data-testid="ticket-info"
        className="w-full"
      >
        {ticket && (
          <Card
            className="w-full"
            title={
              <div className="flex items-center justify-between">
                <Row>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Flex>
                      <Text className="text-xl font-bold ">
                        {ticket?.protocol_number}
                      </Text>
                    </Flex>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <Flex className="pt-5 gap-10 mb-3">
                      <Flex gap={"small"}>
                        {userContext.user?.scopes?.includes(
                          "ticket:create:new_fact"
                        ) && (
                          <Button
                            type="default"
                            status="info"
                            data-testid="open-modal-button"
                            onClick={openModal}
                          >
                            Fato Novo
                          </Button>
                        )}
                        <Button
                          status="info"
                          onClick={redirectToOpenRequirement}
                        >
                          Criar Requerimento
                        </Button>
                      </Flex>
                    </Flex>
                  </Col>
                </Row>
              </div>
            }
          >
            <Flex vertical gap={40}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} lg={12}>
                  <Card>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={12} className="flex flex-col">
                        <Flex gap={"small"}>
                          <Text className="text-base font-medium ">
                            Status:
                          </Text>
                          <Text>
                            {
                              TicketStatus[
                                ticket?.status as keyof typeof TicketStatus
                              ]
                            }
                          </Text>
                        </Flex>
                      </Col>
                      <Col xs={24} sm={12} className="flex flex-col">
                        <Flex gap={"small"}>
                          <Text className="text-base font-medium ">
                            Denúncia:
                          </Text>
                          <Text>{ticket?.protocol_number}</Text>
                        </Flex>
                      </Col>
                      <Col xs={24} sm={12} className="flex flex-col">
                        <Flex gap={"small"}>
                          <Text className="text-base font-medium ">
                            Tipo do Animal:
                          </Text>
                          <Text>{ticket?.species}</Text>
                        </Flex>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Flex gap={"small"}>
                          <Text className="text-base font-medium ">
                            Gênero:
                          </Text>
                          <Text>{ticket?.gender}</Text>
                        </Flex>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Flex gap={"small"}>
                          <Text className="text-base font-medium ">
                            Endereço:
                          </Text>
                          <Text>{ticket?.location_address}</Text>
                        </Flex>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Flex gap={"small"}>
                          <Text className="text-base font-medium ">
                            Bairro:
                          </Text>
                          <Text>{ticket?.neighborhood} </Text>
                        </Flex>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                  <Card className="h-full">
                    <Flex vertical gap={"small"}>
                      <Text className="font-bold">Descrição:</Text>
                      <Text>{ticket?.description}</Text>
                    </Flex>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Flex vertical gap={"small"}>
                  <Text className="font-bold">Imagens:</Text>
                  <Flex wrap gap={"small"}>
                    {ticket.image_url?.map((image, index) => (
                      <div
                        key={index}
                        className="border-2 border-gray-300 rounded-md flex justify-center items-center overflow-hidden"
                      >
                        <Image
                          className="object-cover"
                          width={100}
                          height={100}
                          src={image}
                        />
                      </div>
                    ))}
                  </Flex>
                </Flex>
              </Row>
              <Row>
                <Col span={24}>
                  <Card title="Linha do Tempo">
                    <Timeline
                      mode="left"
                      items={ticket?.ticket_items.map((item) => {
                        const color =
                          TIMELINE_COLOR[item.status as TicketStatus];
                        return {
                          color,
                          children: (
                            <Flex vertical gap={"small"}>
                              {dayjs(item.date).format("DD/MM/YYYY")}
                              <Text>{item.new_fact}</Text>
                            </Flex>
                          ),
                        };
                      })}
                    />
                  </Card>
                </Col>
              </Row>
            </Flex>
          </Card>
        )}
      </Flex>
      <NewFactModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        handleSubmit={handleSubmit}
        statusOptions={Object.entries(TicketStatus).map(([value, label]) => ({
          value,
          label,
        }))}
        form={form}
        handleDatePickerChange={handleDatePickerChange}
        currentStatus={currentStatus}
        updateStatus={updateStatus}
        loading={false}
      />
    </>
  );
};
