import { Card, Flex, Typography } from "antd";
import React from "react";
import { ITutor } from "../../../domain/entities/Tutor";

const { Text } = Typography;

type TutorCardProps = ITutor;

export const TutorCard: React.FC<TutorCardProps> = ({ user }) => {
  return (
    <Card title="Sobre o Tutor">
      <Flex>
        <Flex vertical gap={"small"}>
          <Flex gap="small">
            <Text strong>Nome Completo: </Text>
            <Text>{user.full_name}</Text>
          </Flex>
          <Flex gap="small">
            <Text strong>Email: </Text>
            <Text>{user.email}</Text>
          </Flex>
          <Flex gap="small">
            <Text strong>CPF:</Text>
            <Text>
              {user.id_number.replace(
                /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
                "$1.$2.$3-$4"
              )}
            </Text>
          </Flex>
          <Flex gap="small">
            <Text strong>Bairro:</Text>
            <Text>{user.neighborhood}</Text>
          </Flex>
          <Flex gap="small">
            <Text strong>CEP:</Text>
            <Text>{user.cep.replace(/(\d{5})(\d{3})/, "$1-$2")}</Text>
          </Flex>
          <Flex gap="small">
            <Text strong>Telefone:</Text>
            <Text>
              {user.phone.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3")}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};
