import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { Card, Col, Flex, Row, Select, Typography } from "antd";

import "leaflet/dist/leaflet.css";

import { MapContainer, TileLayer, Marker, Popup, Tooltip } from "react-leaflet";
import { LatLng, Icon } from "leaflet";
import { Button } from "../../components/Button";

import { useDashboard } from "../../hooks/useDashboard";
import { UserContext } from "../../../domain/context/user/UserContext";
import { useConfig } from "../../hooks/useConfig";
import { Metrics } from "../../components/Dashboard/Metrics";
import { ChartsSection } from "../../components/Dashboard/ChartsSection";
import { useReactToPrint } from "react-to-print";
import dayjs from "dayjs";

const { Text } = Typography;

const LAGUNA_GEOJSON = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7575202591056, -28.5016380311139],
      },
      properties: {
        valor: "Ponta da Barra",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.8008769312613, -28.4572466926592],
      },
      properties: {
        valor: "Bela Vista",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7325470647709, -28.3379275681735],
      },
      properties: {
        valor: "Boa Vista",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.8269681285038, -28.6011302212999],
      },
      properties: {
        valor: "Faról de Santa Marta",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.800590051293, -28.5250662164051],
      },
      properties: {
        valor: "Campos Verdes",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7998861685365, -28.5675358755308],
      },
      properties: {
        valor: "Galheta",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.77812223439, -28.4768572837397],
      },
      properties: {
        valor: "Campo de Fora",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7687977044063, -28.445066260364],
      },
      properties: {
        valor: "Laguna Internacional",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.8087131350828, -28.4524924090066],
      },
      properties: {
        valor: "Mato Alto",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.8062215185152, -28.4430035573421],
      },
      properties: {
        valor: "Jardim Juliana",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7869607703099, -28.4915523289952],
      },
      properties: {
        valor: "Ponta das Pedras",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.8235419716538, -28.4416764422995],
      },
      properties: {
        valor: "Cabeçuda",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7874775089784, -28.4722740957274],
      },
      properties: {
        valor: "Esperança",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7834415513434, -28.4954706148038],
      },
      properties: {
        valor: "Vila Vitória",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7727510458268, -28.4883016021655],
      },
      properties: {
        valor: "Navegantes",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7799402930578, -28.4845742184412],
      },
      properties: {
        valor: "Centro",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7787922566666, -28.4931918609166],
      },
      properties: {
        valor: "Magalhães",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7827286609617, -28.4721338523229],
      },
      properties: {
        valor: "Progresso",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7641254785508, -28.3819768704686],
      },
      properties: {
        valor: "Estreito",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7477107764176, -28.3594519537677],
      },
      properties: {
        valor: "Nova Fazenda",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7975570942, -28.4109025843681],
      },
      properties: {
        valor: "Bentos",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7622685832231, -28.403548596391],
      },
      properties: {
        valor: "Praia do Sol",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7365275751852, -28.3653679206808],
      },
      properties: {
        valor: "Itapiruba",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.8108639554964, -28.5696241706923],
      },
      properties: {
        valor: "Canto da Lagoa",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7831443175959, -28.3868670831209],
      },
      properties: {
        valor: "Caputera",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.79296721378, -28.3708385373633],
      },
      properties: {
        valor: "Perrixil",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.8066590867958, -28.4280317953161],
      },
      properties: {
        valor: "Barranceira",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7989187604267, -28.4459726323899],
      },
      properties: {
        valor: "Bairro Industrial",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7673915591326, -28.4856191635975],
      },
      properties: {
        valor: "Mar Grosso",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.8529728615499, -28.4466986427738],
      },
      properties: {
        valor: "Morro Grande",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7849569522155, -28.4260428362401],
      },
      properties: {
        valor: "Barbacena",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.791966709727, -28.4624786013883],
      },
      properties: {
        valor: "Portinho",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.8614397198939, -28.4687554520104],
      },
      properties: {
        valor: "Ponta do Daniel",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.8579697567525, -28.4575187990694],
      },
      properties: {
        valor: "Figueira",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.8516909953652, -28.4352918709225],
      },
      properties: {
        valor: "Bananal",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.8905037587332, -28.481463968912],
      },
      properties: {
        valor: "Ribeirão Pequeno",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.9004274275297, -28.4943028308551],
      },
      properties: {
        valor: "Ribeirão Grande",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.8980505326604, -28.5102283468377],
      },
      properties: {
        valor: "Cortiçal",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.8756348692821, -28.474267786558],
      },
      properties: {
        valor: "Parobé",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7634345835574, -28.5256242515932],
      },
      properties: {
        valor: "Praia da Tereza",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.8839655477358, -28.5196107853844],
      },
      properties: {
        valor: "Madre",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7690363038073, -28.5182232160194],
      },
      properties: {
        valor: "Passagem da Barra",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7919645280871, -28.5437726758768],
      },
      properties: {
        valor: "Santa Marta Pequena",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.8407963115787, -28.5921488155771],
      },
      properties: {
        valor: "Cigana",
        id_camada: 15,
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-48.7704607391263, -28.5373744105861],
      },
      properties: {
        valor: "Ilhota",
        id_camada: 15,
      },
    },
  ],
};

export const Dashboard: React.FC = () => {
  const reportRef = useRef<HTMLDivElement>(null);
  const promiseResolveRef = useRef<Function | null>(null);
  const printDocument = useReactToPrint({
    content: () => reportRef.current,
    bodyClass: "p-2",
    pageStyle: "@page { size: A3; margin: 0mm; }",

    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
  });

  const [selectedNeighborhood, setSelectedNeighborhood] = useState<string>("");
  const [isPrinting, setIsPrinting] = useState<boolean>(false);

  const [selectedMonth, setSelectedMonth] = useState<number>(5);

  const userContext = useContext(UserContext);

  const { neighborhood } = useConfig();

  const {
    loading,
    dashboard,
    actions: { handleGetDashboard },
  } = useDashboard();

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  useEffect(() => {
    if (userContext.user?.scopes?.includes("dashboard:read")) {
      handleGetDashboard(selectedNeighborhood, selectedMonth);
    }
  }, [handleGetDashboard, selectedNeighborhood, selectedMonth, userContext]);

  const mapIcon = new Icon({
    iconUrl:
      "https://propatadev.s3.sa-east-1.amazonaws.com/20240517220618-5b150efe22ad0b9aa0edac933c9064f5590afa99f22eac10fa.png",
    iconSize: [27, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
    shadowSize: [41, 41],
  });

  const center = useMemo(() => {
    const centerNeighborhood = LAGUNA_GEOJSON.features.find(
      (feature) => feature.properties.valor === "Centro"
    );

    if (!centerNeighborhood) {
      return new LatLng(0, 0);
    }

    return new LatLng(
      centerNeighborhood.geometry.coordinates[1],
      centerNeighborhood.geometry.coordinates[0]
    );
  }, []);

  if (!userContext.user?.scopes?.includes("dashboard:read")) {
    return (
      <>
        <Flex className="w-full h-full mt-20" justify="center">
          <Text className="text-3xl font-bold">
            Bem-vindo a Plataforma Propata
          </Text>
        </Flex>
      </>
    );
  }

  return (
    <Card
      className="w-full"
      title={
        <Flex justify="flex-end">
          <Button loading={loading} status="info" onClick={printDocument}>
            Gerar Relatório
          </Button>
        </Flex>
      }
    >
      <Flex vertical gap={"large"} className="w-full print:p-5" ref={reportRef}>
        <Flex
          justify="center"
          align="center"
          className="screen:hidden print:flex"
        >
          <Text strong className="text-3xl">
            Relatório {dayjs().format("MM/YYYY")} - Propata{" "}
            {process.env.REACT_APP_FLAG}
          </Text>
        </Flex>
        <Row
          className="w-full h-full min-h-[600px] print:min-h-fit"
          gutter={[16, 16]}
        >
          <Col xs={24} md={24} lg={12} className="min-h-[600px] print:hidden">
            <MapContainer
              center={center}
              zoom={11}
              className="w-full h-full !rounded-lg "
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

              {LAGUNA_GEOJSON.features.map((feature) => (
                <Marker
                  icon={mapIcon}
                  key={feature.properties.valor}
                  position={
                    new LatLng(
                      feature.geometry.coordinates[1],
                      feature.geometry.coordinates[0]
                    )
                  }
                  eventHandlers={{
                    click: () =>
                      setSelectedNeighborhood(feature.properties.valor),
                  }}
                >
                  <Popup>{feature.properties.valor}</Popup>
                  <Tooltip>{feature.properties.valor}</Tooltip>
                </Marker>
              ))}
            </MapContainer>
          </Col>
          <Col xs={24} md={24} lg={isPrinting ? 24 : 12}>
            <Flex gap={"large"} vertical className="w-full h-full print:h-auto">
              <Flex className="w-full" align="center" gap={"small"}>
                <Text>Bairros: </Text>
                {isPrinting ? (
                  <Text>
                    {selectedNeighborhood ? selectedNeighborhood : "Todos"}
                  </Text>
                ) : (
                  <Select
                    showSearch
                    className="flex-1"
                    value={selectedNeighborhood}
                    options={[
                      { value: "", label: "Todos" },
                      ...neighborhood.map((neighborhood) => ({
                        label: neighborhood,
                        value: neighborhood,
                      })),
                    ]}
                    onChange={(value) => setSelectedNeighborhood(value)}
                  />
                )}
              </Flex>

              <Card className="h-full" classNames={{ body: "!h-full" }}>
                <Metrics
                  dashboard={dashboard}
                  loading={loading}
                  isPrint={isPrinting}
                  selectedNeighborhood={selectedNeighborhood}
                  setSelectedNeighborhood={setSelectedNeighborhood}
                />
              </Card>
            </Flex>
          </Col>
        </Row>
        <ChartsSection
          loading={loading}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          isPrint={isPrinting}
          dashboard={dashboard}
        />
      </Flex>
    </Card>
  );
};
