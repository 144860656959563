import { Flex, Form, Input, Spin, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../components/Button";

import { FaCircleCheck } from "react-icons/fa6";
import { resetPassword } from "../../data/service/tutor";
import { toast } from "react-toastify";
import { ROUTES } from "../Router";

import { LogoLogin } from "../assets/icon/LogoLogin";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const { Text } = Typography;

export const ResetPassword: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [wasSuccess, setWasSuccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!searchParams.get("token")) {
      toast.error("Usuário inválido");
      navigate(ROUTES.LOGIN);
    }
  }, [navigate, searchParams]);

  const handleResetPassword = useCallback(
    async (data: { newPassword: string }) => {
      setLoading(true);
      try {
        const token = searchParams.get("token");
        if (token) {
          await resetPassword(token, data.newPassword);
          setWasSuccess(true);
        }
      } catch (error: any) {
        new Error(error);
      } finally {
        setLoading(false);
      }
    },
    [searchParams]
  );

  const validatePassword = (_: any, value: string) => {
    if (!value) {
      return Promise.reject(new Error("Senha é obrigatória"));
    }
    if (value.length < 8) {
      return Promise.reject(new Error("Senha deve ter no mínimo 8 caracteres"));
    }
    if (!/[A-Z]/.test(value)) {
      return Promise.reject(
        new Error("Senha deve ter pelo menos uma letra maiúscula")
      );
    }
    if (!/[a-z]/.test(value)) {
      return Promise.reject(
        new Error("Senha deve ter pelo menos uma letra minúscula")
      );
    }
    if (!/[0-9]/.test(value)) {
      return Promise.reject(new Error("Senha deve ter pelo menos um número"));
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      return Promise.reject(
        new Error("Senha deve ter pelo menos um caractere especial")
      );
    }
    return Promise.resolve();
  };

  return (
    <Flex className="w-full" align="center" justify="center">
      <Spin spinning={loading}>
        <div className="flex flex-col items-center gap-10 w-[350px]">
          <LogoLogin className="w-52" />
          {wasSuccess ? (
            <>
              <Text className="text-lg text-center font-bold">
                Senha alterada com sucesso
              </Text>
              <FaCircleCheck className="text-6xl text-green-700" />
            </>
          ) : (
            <Form
              layout="vertical"
              onFinish={handleResetPassword}
              className="w-full flex flex-col gap-5"
            >
              <Form.Item
                rules={[{ validator: validatePassword }]}
                label="Nova senha"
                name="newPassword"
              >
                <Input.Password
                  type="password"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Button
                type="primary"
                status="info"
                className="w-full"
                htmlType="submit"
              >
                Alterar senha
              </Button>
            </Form>
          )}
        </div>
      </Spin>
    </Flex>
  );
};
